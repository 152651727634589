import Button from '@mui/material/Button';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Modal } from '../../components';
import { DEVICE_SERIAL_CODE_MASK } from '../../config/devices';
import deviceActions, { selectors as devicesSelectors } from '../../modules/devices';
import { selectors as sitesSelectors } from '../../modules/sites';
import StatusMessage from '../DeviceModal/StatusMessage';
import Loading from '../DeviceModal/X2NDeviceForm/Loading';
import OneStep from '../DeviceModal/X2NDeviceForm/OneStep';
import messagesStepOne from '../DeviceModal/X2NDeviceForm/OneStep/messages';
import FinishedStep from './FinishedStep';
import messages from './messages';

const VerifyDeviceModal = ({
  companyId,
  device,
  isFinished,
  isWait,
  my2nId,
  onClose,
  onVerify,
  open,
  site,
  siteId,
}) => {
  const [step, setStep] = useState(1);
  useEffect(() => {
    if (isWait && step !== 2) {
      setStep(2);
    } else if (isFinished && step !== 3) {
      setStep(3);
    }
  }, [isWait, isFinished, step]);

  useEffect(() => {
    if (open) {
      setStep(1);
    }
  }, [open]);

  const validationSchema = Yup.object().shape({
    deviceType: Yup.string().required(<FormattedMessage {...messages.verifyDeviceModalRequired} />),
    name: Yup.string()
      .required(<FormattedMessage {...messages.verifyDeviceModalRequired} />)
      .max(50, <FormattedMessage {...messages.verifyDeviceModalTooLong} />),
    securityCode: Yup.string().when('isIDT', {
      is: false,
      then: (schema) =>
        schema
          .required(<FormattedMessage {...messages.verifyDeviceModalRequired} />)
          .test(
            'isValidSecurityCode',
            <FormattedMessage {...messages.verifyDeviceModalSecurityCodeNoValid} />,
            (value) => new RegExp(/^([A-Z0-9]{4})-?([A-Z0-9]{4})-?([A-Z0-9]{4})-?([A-Z0-9]{4})$/i).test(value)
          ),
    }),
    serialNumber: Yup.string()
      .required(<FormattedMessage {...messages.verifyDeviceModalRequired} />)
      .test('isValidSerialNumber', <FormattedMessage {...messages.verifyDeviceModalSerialNumberNoValid} />, (value) =>
        new RegExp(DEVICE_SERIAL_CODE_MASK).test(value)
      ),
  });

  const getActions = (form) => {
    if (step === 1) {
      return (
        <Button color="primary" disabled={!form.isValid} onClick={form.handleSubmit} type="submit" variant="contained">
          <FormattedMessage {...messagesStepOne.formActionNextBtn} />
        </Button>
      );
    }

    return null;
  };

  return (
    open && (
      <Formik
        enableReinitialize
        initialValues={{
          deviceType: device.deviceType,
          isIDT: false,
          name: device.name,
          securityCode: '',
          serialNumber: device.serialNumber,
          storeHash: false,
        }}
        onSubmit={onVerify}
        render={(formProps) => (
          <Form autoComplete="off" noValidate>
            <Modal
              actions={getActions(formProps)}
              fullWidth
              headerVariant="primary"
              maxWidth="md"
              name="verify-device-modal"
              noCloseReasons={['escapeKeyDown', 'backdropClick']}
              onClose={onClose}
              open={open}
              title={<FormattedMessage {...messages.verifyDeviceModalTitle} />}
            >
              <StatusMessage />
              {step === 1 && siteId === site?.id && (
                <OneStep
                  apartments={[]}
                  companyId={companyId}
                  deviceTypes={[]}
                  floors={[]}
                  formProps={formProps}
                  my2nId={my2nId}
                  serialNumberDisabled
                  site={site}
                />
              )}
              {step === 2 && <Loading onClose={onClose} />}
              {step === 3 && <FinishedStep device={device} onClose={onClose} />}
            </Modal>
          </Form>
        )}
        validationSchema={validationSchema}
      />
    )
  );
};

VerifyDeviceModal.propTypes = {
  companyId: PropTypes.number.isRequired,
  device: PropTypes.shape({
    deviceType: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    serialNumber: PropTypes.string,
  }).isRequired,
  isFinished: PropTypes.bool.isRequired,
  isWait: PropTypes.bool.isRequired,
  my2nId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onVerify: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  site: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  siteId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  device: devicesSelectors.getDeviceVerifyDeviceModal(state),
  isFinished: devicesSelectors.isFinishedVerifyDeviceModal(state),
  isWait: devicesSelectors.isVerifyWaitModal(state),
  my2nId: sitesSelectors.getMy2nId(state),
  open: devicesSelectors.isVerifyDeviceModalOpen(state),
  site: sitesSelectors.getSiteData(state),
});

const mapDispatchToProps = {
  onClose: deviceActions.verifyDeviceHideModal,
  onVerify: deviceActions.verifyDevice,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyDeviceModal);
