import Cancel from '@mui/icons-material/Cancel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useGetGlobalInfo } from '../../hooks/useGetGlobalInfo';
import actions from '../../modules/apartments';
import { IUser } from '../../modules/user/store';
import { ActionModalError } from '../_DesignSystemWrappers/Modal';
import StandardUser from '../UserApartmentModal/StandardUser';
import messages from './messages';

interface IRevokeApartmentAdminRightsModal {
  onClose: () => void;
  open: boolean;
  selectedUser: IUser;
}

function RevokeApartmentAdminRightsModal({ onClose, open, selectedUser }: IRevokeApartmentAdminRightsModal) {
  const { apartmentId, dispatch, formatMessage } = useGetGlobalInfo();

  const handleSubmit = () => {
    if (apartmentId !== undefined) {
      dispatch(actions.revokeApartmentAdmin(apartmentId, selectedUser.id));
    }
    onClose();
  };

  return (
    <ActionModalError
      icon={<Cancel />}
      isPrimaryButtonDisabled={false}
      muiDialogProps={{ maxWidth: 'xs' }}
      onClose={onClose}
      open={open}
      primaryButtonAction={handleSubmit}
      primaryButtonText={formatMessage(messages.AAModalRevokeRightsSubmit)}
      secondaryButtonText={formatMessage(messages.AAModalRevokeRightsCancel)}
      title={formatMessage(messages.AAModalRevokeRightsTitle)}
      testId="revoke-apartment-admin-rights-modal"
    >
      <Stack direction="column" spacing={2} sx={{ my: 2 }}>
        <Typography variant="body2">
          <FormattedMessage {...messages.AAModalRevokeRightsText1} />
        </Typography>
        <StandardUser email={selectedUser.email} firstName={selectedUser.firstName} lastName={selectedUser.lastName} />
      </Stack>
    </ActionModalError>
  );
}

export default RevokeApartmentAdminRightsModal;
