import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { connect, getIn } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormikFields } from '../../../../components';
import messages from './messages';

const StoreHashControl = ({ formik }) => {
  const { setFieldValue, values } = formik;
  const isIDT = getIn(values, 'isIDT');
  useEffect(() => {
    if (isIDT) {
      setFieldValue('storeHash', false);
    }
  }, [isIDT]);

  return (
    <>
      <FormikFields.Checkbox
        disabled={isIDT}
        label={<FormattedMessage {...messages.storeHashControlStoreHashLabel} />}
        name="storeHash"
        onClick={() => {
          if (!isIDT) {
            setFieldValue('storeHash', !getIn(values, 'storeHash'));
          }
        }}
        onPointerDown={(event) => event.preventDefault()}
      />
      <Tooltip
        placement="right"
        sx={{
          '& .MuiTooltip-tooltip': {
            maxWidth: '200px',
          },
        }}
        title={<FormattedMessage {...messages.storeHashControlStoreHashTooltip} />}
      >
        <InfoOutlinedIcon />
      </Tooltip>
    </>
  );
};

StoreHashControl.propTypes = {
  formik: PropTypes.shape({
    setFieldValue: PropTypes.func,
    values: PropTypes.shape({
      isIDT: PropTypes.bool,
      storeHash: PropTypes.bool,
    }),
  }).isRequired,
};

export default connect(StoreHashControl);
