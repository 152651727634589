import { accessControlApi } from './accessControlApi';

export type accessControlTagTypes = 'access-control' | 'site-settings';

export const enhancedAccessControlApi = accessControlApi.enhanceEndpoints<accessControlTagTypes>({
  addTagTypes: ['access-control'],
  endpoints: {
    getUserCredentialsValidity: {
      providesTags: [{ id: 'user-credentials-validity', type: 'access-control' }],
    },
    updateSiteGuestPinSettings: {
      invalidatesTags: [{ id: 'site', type: 'site-settings' }],
    },
  },
});
