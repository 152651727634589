import AddIcon from '@mui/icons-material/Add';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Alert, ApartmentDataGrid, DataGridActionButton, DataGridSearchField, Title } from '../../components';
import { SITE_TYPE } from '../../config/sites';
import { changeFilter, dataGridProps, getDataGridSelector, useButtonText } from '../../helpers/dataGrid';
import { useUrlParams } from '../../helpers/urlParams';
import useBreakpoints from '../../helpers/useBreakpoints';
import { useModal } from '../../hooks/useModal';
import actions, { NAME as APARTMENTS_MODULE, selectors } from '../../modules/apartments';
import commonActions from '../../modules/common';
import { selectors as sitesSelectors } from '../../modules/sites';
import ApartmentCreateDialog from './ApartmentCreateModal';
import messages from './messages';

const ApartmentsOverview = ({
  apartmentsTable,
  didInvalidFloors,
  floors,
  onAddApartment,
  onLoadApartments,
  onLoadFloors,
  onSearch,
  site,
}) => {
  const { companyId, siteId } = useUrlParams();
  const { downSm } = useBreakpoints();
  const [fulltext, setFulltext] = useState(apartmentsTable.filter.fulltext || '');
  const [showWarning, setShowWarning] = useState(true);

  useEffect(() => {
    onLoadFloors(companyId, siteId);
  }, [didInvalidFloors]);

  const onChangeFilter = ({ target }) => {
    const { name, value } = target;
    setFulltext(value);
    changeFilter(onSearch, actions.getSiteApartmentsList, {
      ...apartmentsTable,
      companyId,
      siteId,
    })(name, value);
  };

  const handleOpenCreateModal = () => {
    onLoadFloors(companyId, siteId);
    onOpenApartmentCreateModal();
  };

  const { Modal: ApartmentCreateModal, onOpen: onOpenApartmentCreateModal } = useModal({
    Modal: ApartmentCreateDialog,
  });

  return (
    <>
      <Title
        heading={<FormattedMessage {...messages.apartmentsOverviewTitle} />}
        rightSide={<DataGridSearchField fulltext={fulltext} onChangeFilter={onChangeFilter} />}
        rightSideActions={
          <DataGridActionButton
            onClick={handleOpenCreateModal}
            startIcon={downSm ? <CreateNewFolderIcon /> : <AddIcon />}
            variant={downSm ? 'text' : 'contained'}
          >
            {useButtonText(messages.apartmentsOverviewCreateApartment, messages.apartmentsOverviewAddApartmentMobile)}
          </DataGridActionButton>
        }
      />
      {site.type === SITE_TYPE.UNKNOWN && showWarning && (
        <Alert onClose={() => setShowWarning(false)} severity="warning">
          <FormattedMessage {...messages.apartmentsOverviewWarning} values={{ limit: site.apartmentDeviceLimit }} />
        </Alert>
      )}
      {siteId === site?.id && (
        <ApartmentDataGrid
          {...apartmentsTable}
          companyId={companyId}
          floors={floors}
          onLoadApartments={onLoadApartments}
          site={site}
        />
      )}
      <ApartmentCreateModal
        floors={floors}
        onCreateApartment={onAddApartment}
        siteType={site?.type || SITE_TYPE.UNKNOWN}
      />
    </>
  );
};

ApartmentsOverview.propTypes = {
  apartmentsTable: dataGridProps.isRequired,
  didInvalidFloors: PropTypes.bool.isRequired,
  floors: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAddApartment: PropTypes.func.isRequired,
  onLoadApartments: PropTypes.func.isRequired,
  onLoadFloors: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  site: PropTypes.shape({
    apartmentDeviceLimit: PropTypes.number,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.oneOf(Object.keys(SITE_TYPE)),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  apartmentsTable: getDataGridSelector(state, APARTMENTS_MODULE, 'list'),
  didInvalidFloors: selectors.getDidInvalidFloors(state),
  floors: selectors.getFloors(state),
  site: sitesSelectors.getSiteData(state),
});

const mapDispatchToProps = {
  onAddApartment: actions.addApartment,
  onLoadApartments: actions.getSiteApartmentsList,
  onLoadFloors: actions.getSiteFloorsList,
  onSearch: commonActions.search,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApartmentsOverview);
