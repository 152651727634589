import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import {
  My2NAppAccessModal as My2NAppAccessModalComponent,
  UserPinModalNew as UserPinModalNewComponent,
  UserRfidModalNew as UserRfidModalNewComponent,
} from '../../../components';
import RowMenu from '../../../components/DataGrid/RowMenu';
import { useHasFeature } from '../../../components/Feature/Feature';
import SetMy2NAppAccessMenuItem from '../../../components/SetMy2NAppAccessMenuItem';
import AssignNewCard from '../../../components/SiteUsersDataGrid/SiteUsersRowMenu/AssignNewCard';
import DeleteUser from '../../../components/SiteUsersDataGrid/SiteUsersRowMenu/DeleteUser';
import EditUser from '../../../components/SiteUsersDataGrid/SiteUsersRowMenu/EditUser';
import GenerateNewPin from '../../../components/SiteUsersDataGrid/SiteUsersRowMenu/GenerateNewPin';
import ManageAdminRights from '../../../components/SiteUsersDataGrid/SiteUsersRowMenu/ManageAdminRights';
import SetAccessGroups from '../../../components/SiteUsersDataGrid/SiteUsersRowMenu/SetAccessGroups';
import ApartmentUserDeleteDialog from '../../../components/UserDeleteModal/ApartmentUserDeleteModal';
import { FE_ROLES } from '../../../config/consts';
import featureFlags from '../../../config/featureFlags';
import { useModal } from '../../../hooks/useModal';
import { getApartmentDevices } from '../../../modules/apartments/selectors';
import { VisitorModalContext } from '../../../modules/commonTypes';
import { selectors as sitesSelectors } from '../../../modules/sites';
import userActions, { selectors as userSelectors } from '../../../modules/user';
import { isUserGuest } from '../../../modules/user/utils';
import VisitorModal from '../../../pages/apartmentAdmin/ApartmentAdminUsersOverviewPage/components/VisitorModal/VisitorModal';

function ApartmentUsersRowMenu({
  companyId,
  currentUser,
  isSiteMdu,
  onOpenAccessGroupsModal,
  onOpenGrantApartmentAdminRightsModal,
  onOpenRevokeApartmentAdminRightsModal,
  onSetSelectedUser,
  siteData,
  siteId,
  user,
}) {
  const apartmentDevices = useSelector(getApartmentDevices).data || [];
  const isUserBleCredentialFFEnable = useHasFeature(featureFlags.USER_BLE_CREDENTIAL);

  const { Modal: My2NAppAccessModal, onOpen: onOpenActivateSetMy2NAppAccess } = useModal({
    Modal: My2NAppAccessModalComponent,
  });
  const { Modal: UserPinModalNew, onOpen: onOpenUserPinModal } = useModal({
    Modal: UserPinModalNewComponent,
  });
  const { Modal: UserRfidModalNew, onOpen: onOpenUserRfidModal } = useModal({
    Modal: UserRfidModalNewComponent,
  });
  const { Modal: ApartmentUserDeleteModal, onOpen: onOpenApartmentUserDeleteModal } = useModal({
    Modal: ApartmentUserDeleteDialog,
  });
  const { Modal: VisitorUModal, onOpen: onOpenVisitorUModal } = useModal({
    Modal: VisitorModal,
  });

  return (
    <>
      <RowMenu
        menuItems={
          <Box>
            <EditUser companyId={companyId} siteId={siteId} userId={user.id} />
            {isSiteMdu && user.role !== FE_ROLES.GUEST && user.role !== FE_ROLES.APARTMENT_GUEST && (
              <ManageAdminRights
                onOpenGrantAdminRightsModal={onOpenGrantApartmentAdminRightsModal}
                onOpenRevokeAdminRightsModal={onOpenRevokeApartmentAdminRightsModal}
                onSetSelectedUser={onSetSelectedUser}
                user={user}
              />
            )}
            <Divider />
            <SetAccessGroups onOpenAccessGroupsModal={onOpenAccessGroupsModal} userId={user.id} />
            <GenerateNewPin
              disabled={user.credentials.includes('PIN')}
              onOpenGenerateNewPinModal={onOpenUserPinModal}
              onOpenVisitorModal={onOpenVisitorUModal}
              user={user}
            />
            {!isUserGuest(user.role) && (
              <AssignNewCard
                disabled={user.credentials.includes('RFID')}
                onOpenAssignNewCardModal={onOpenUserRfidModal}
                user={user}
              />
            )}
            {isUserBleCredentialFFEnable && !isUserGuest(user.role) && (
              <SetMy2NAppAccessMenuItem
                disabled={user.role === FE_ROLES.LOGIN_LESS}
                isBleActive={user.credentials.includes('BT')}
                onClick={onOpenActivateSetMy2NAppAccess}
              />
            )}
            <Divider />
            <DeleteUser
              currentUser={currentUser}
              onOpenDeleteUserModal={onOpenApartmentUserDeleteModal}
              user={user}
              siteIdsInfo={{ companyId, siteId }}
            />
          </Box>
        }
      />
      {isUserBleCredentialFFEnable && (
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        <My2NAppAccessModal context="APARTMENT_USERS_LIST" onActivate={() => {}} user={user} />
      )}
      <UserPinModalNew context="APARTMENT_USERS_LIST" user={user} />
      <UserRfidModalNew context="APARTMENT_USERS_LIST" user={user} />
      <ApartmentUserDeleteModal user={user} />
      <VisitorUModal
        msgContext={VisitorModalContext.AA_MODAL_NEW_GUEST_PIN}
        apartmentInfo={{ companyId: companyId, siteId: siteId }}
        apartmentDevices={user?.apartments.length ? apartmentDevices : []}
        siteName={siteData.name}
        user={user}
        isRegenerate
      />
    </>
  );
}

ApartmentUsersRowMenu.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  isSiteMdu: PropTypes.bool.isRequired,
  onOpenAccessGroupsModal: PropTypes.func.isRequired,
  onOpenGrantApartmentAdminRightsModal: PropTypes.func.isRequired,
  onOpenRevokeApartmentAdminRightsModal: PropTypes.func.isRequired,
  onSetSelectedUser: PropTypes.func.isRequired,
  siteData: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  siteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  user: PropTypes.shape({
    apartments: PropTypes.arrayOf(PropTypes.object),
    companies: PropTypes.arrayOf(PropTypes.object),
    credentials: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.number,
    role: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: userSelectors.getCurrentUserSelector(state),
  isSiteMdu: sitesSelectors.isSiteMdu(state),
});

const mapDispatchToProps = {
  onOpenAccessGroupsModal: userActions.accessGroupsModalOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApartmentUsersRowMenu);
