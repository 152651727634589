import { ExpandLess, ExpandMore } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import hipmoSvg from '../../../../../assets/font-icons/mobile-small.svg';
import mvideoSvg from '../../../../../assets/mvideo.svg';
import { FilledInfoBar } from '../../../../../components/_DesignSystemWrappers/InfoBar/FilledInfoBar';
import { SERVICE } from '../../../../../config/devices';
import { IDevice } from '../../../../../modules/devices/store/deviceStore';
import { DetailItem } from '../../components/DetailItem';
import messages from '../messages';
import MVAppLoginInfo from '../MVAppLoginInfo';
import MVStatus from '../MVStatus';

const styles = {
  activeMVImage: {
    display: 'block',
    width: 40,
  },
  closedCollapse: {
    mb: '-24px !important',
  },
  collapseButton: {
    ml: '-8px !important',
    width: 'fit-content',
  },
  collapseButtonContent: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'initial',
  },
  inactiveMVImage: {
    display: 'block',
    filter: 'grayscale(100%)',
    opacity: 0.6,
    width: 40,
  },
};

export interface IMVMultipleDevices {
  device: IDevice;
  onOpenActivateMVModal: () => void;
  onOpenDeactivateMVModal: () => void;
  onOpenInfoModal: () => void;
  setCurrentDevice: (device: IDevice) => void;
  hasExceededMVLimit: boolean;
  isUnlicensed: boolean;
}

function MVMultipleDevices({
  device,
  hasExceededMVLimit,
  isUnlicensed,
  onOpenActivateMVModal,
  onOpenDeactivateMVModal,
  onOpenInfoModal,
  setCurrentDevice,
}: IMVMultipleDevices) {
  const isActive = !!device?.services?.[SERVICE.MOBILE_VIDEO]?.active;
  const [open, setOpen] = useState(false);

  const onClick = (device: IDevice) => {
    isActive ? onOpenDeactivateMVModal() : onOpenActivateMVModal();
    setCurrentDevice(device);
  };

  return (
    <DetailItem
      title={<FormattedMessage {...messages.AAUserDetailMobileVideoMultipleTitle} />}
      titleIcon={
        <Box flexWrap="wrap" fontWeight={700}>
          <img alt="hipmo device" src={hipmoSvg} style={{ margin: '0 4px', width: 20 }} />
          {device.name}
        </Box>
      }
    >
      <Stack key={device.id} spacing={3}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <img alt="Mobile Video" src={mvideoSvg} style={isActive ? styles.activeMVImage : styles.inactiveMVImage} />
          <MVStatus device={device} isActive={isActive} />
          <Button
            color={isActive ? 'info' : 'primary'}
            onClick={() => onClick(device)}
            startIcon={isActive ? <HighlightOffIcon /> : <DoneIcon />}
            variant="contained"
            disabled={hasExceededMVLimit || isUnlicensed}
          >
            <FormattedMessage
              {...(isActive
                ? { ...messages.AAUserDetailMobileVideoDeactivateMultiple }
                : { ...messages.AAUserDetailMobileVideoActivateMultiple })}
            />
          </Button>
        </Stack>
        {(hasExceededMVLimit || isUnlicensed) && (
          <FilledInfoBar>
            <Typography variant="body1">
              <FormattedMessage
                {...(isUnlicensed
                  ? messages.AAUserDetailMobileVideoUnlicensed
                  : messages.AAUserDetailMobileVideoLimitExceeded)}
              />
            </Typography>
          </FilledInfoBar>
        )}
        {isActive && (
          <>
            <Button onClick={() => setOpen(!open)} sx={styles.collapseButton}>
              <Typography color="secondary.main" fontWeight="bold" sx={styles.collapseButtonContent} variant="caption">
                <FormattedMessage {...messages.AAUserDetailMobileVideoMultipleAppCollapsed} />
                {open ? <ExpandLess /> : <ExpandMore />}
              </Typography>
            </Button>
            <Collapse in={open} timeout="auto" sx={!open ? styles.closedCollapse : undefined}>
              <MVAppLoginInfo
                qrCodeUrl={device?.services?.[SERVICE.NOTIFICATION]?.qrCodeUrl}
                onOpenInfoModal={onOpenInfoModal}
              />
            </Collapse>
          </>
        )}
      </Stack>
    </DetailItem>
  );
}

export default MVMultipleDevices;
