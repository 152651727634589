import React, { useEffect } from 'react';
import { IApartment } from '../../../modules/apartments/store';
import { IDevice } from '../../../modules/devices/store/deviceStore';
import EmptyForm from './EmptyForm';
import Form from './Form';
import { getDevicesWithCallSettings } from './helpers/helpers';
import * as stateActions from './helpers/stateActions';
import { IState } from './helpers/types';
import { useFetchCallSettingsData } from './hooks/fetchCallSettingsData';
import { useGetCallSettingsState } from './hooks/useGetCallSettingsState';

export interface ICallSettingsStepData {
  addedDevice: IDevice;
  companyId: number;
  siteId: number;
  handleChange: (callSettings: {
    state: IState;
    availableApartments: IApartment[];
    availableDevices: IDevice[];
  }) => void;
}

function CallSettingsStep(data: ICallSettingsStepData): JSX.Element {
  useFetchCallSettingsData();
  const { availableApartments, availableDevices, ringingGroupSizeLimit, setState, state } = useGetCallSettingsState();

  const isFiveAndMoreDevices = () => state.devicesCallSettings.length >= 5;

  useEffect(() => {
    data.handleChange({ availableApartments, availableDevices, state });
  }, [state]);

  if (getDevicesWithCallSettings(availableDevices).length === 0) {
    return <EmptyForm deviceType={data.addedDevice.type} />;
  }

  return (
    <Form
      addedDevice={data.addedDevice}
      availableDevices={availableDevices}
      companyId={data.companyId}
      isDeviceFetching={state.isFetchingSelectedDevice}
      isFiveAndMoreDevices={isFiveAndMoreDevices()}
      onAddButton={(deviceId) => stateActions.addButton({ setState, state }, deviceId)}
      onAddCallDestination={(deviceId, buttonIndex, isDevice) =>
        stateActions.addCallDestination(
          { setState, state },
          data.addedDevice,
          deviceId,
          buttonIndex,
          availableApartments,
          isDevice
        )
      }
      onAddDevice={() => stateActions.addDevice({ setState, state })}
      onChangeButtonName={(deviceId, buttonIndex, name) =>
        stateActions.changeButtonName({ setState, state }, deviceId, buttonIndex, name)
      }
      onClearDestination={(deviceId, buttonIndex, optionKey) =>
        stateActions.clearCallDestination({ setState, state }, deviceId, buttonIndex, optionKey)
      }
      onClearDevice={(deviceId) => stateActions.clearDevice({ setState, state }, deviceId)}
      onSelectDevice={(deviceId: number, previousDeviceId?: number) =>
        stateActions.loadSelectedDevice({ setState, state }, deviceId, (device) =>
          stateActions.selectDevice(
            { setState, state },
            availableDevices,
            availableApartments,
            device,
            previousDeviceId
          )
        )
      }
      ringingGroupSizeLimit={ringingGroupSizeLimit}
      selectedDevices={state.devicesCallSettings}
      siteId={data.siteId}
    />
  );
}

export default CallSettingsStep;
