import AddIcon from '@mui/icons-material/Add';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useModal } from '../../hooks/useModal';
import { IUser } from '../../modules/user/store';
import RemoveUserRfidCardDialog from '../RemoveUserRfidCardModal';
import UserRfidModalNewComponent from '../UserRfidModalNew';
import CredentialAlert from './CredentialAlert';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import messages from './messages';
import PanelRfid from './PanelRfid';
import SectionTitle from './SectionTitle';

interface ISectionRfid {
  user: IUser;
  userRfidCardData: {
    id: string;
    rfidCode: string;
  };
}

const SectionRfid = ({ user, userRfidCardData }: ISectionRfid) => {
  const { formatMessage, isCompanyAdmin, isSiteAdmin } = useGetGlobalHooks();
  const isEmpty = !userRfidCardData?.rfidCode;

  const { Modal: RemoveUserRfidCardModal, onOpen: onOpenRemoveUserRfidCardDialog } = useModal({
    Modal: RemoveUserRfidCardDialog,
  });
  const { Modal: UserRfidModalNew, onOpen: onOpenUserRfidModal } = useModal({
    Modal: UserRfidModalNewComponent,
  });

  const [displayInfoBox, setDisplayInfoBox] = useState(isEmpty);

  const toggleDisplayInfoBox = () => {
    setDisplayInfoBox((prevState) => !prevState);
  };

  return (
    <>
      <SectionTitle
        isEmpty={isEmpty}
        onInfoClick={toggleDisplayInfoBox}
        startIcon={<CreditCardIcon color={isEmpty ? 'inherit' : 'primary'} />}
        title={formatMessage(messages.userAccessFormRfidTitle)}
      />
      {isEmpty ? (
        <Button color="primary" onClick={onOpenUserRfidModal} startIcon={<AddIcon />} sx={{ mb: 2 }} variant="outlined">
          <FormattedMessage {...messages.userAccessFormRfidAssignRfidCard} />
        </Button>
      ) : (
        <PanelRfid rfidCode={userRfidCardData?.rfidCode} />
      )}
      {displayInfoBox && (
        <CredentialAlert
          infoText={formatMessage(messages.userAccessFormRfidInfoDescription, {
            br: <br />,
          })}
        />
      )}
      {!isEmpty && (
        <Grid sx={{ mt: 4 }}>
          <Button color="error" disabled={!(isCompanyAdmin || isSiteAdmin)} onClick={onOpenRemoveUserRfidCardDialog}>
            <FormattedMessage {...messages.removeUserRfidCardButtonRemoveCard} />
          </Button>
        </Grid>
      )}
      <UserRfidModalNew context="SITE_USER_DETAIL" user={user} />
      <RemoveUserRfidCardModal user={user} userRfidCardData={userRfidCardData} />
    </>
  );
};

export default SectionRfid;
