import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { DEVICE_TYPE, VERIFICATION_STATUS } from '../../../config/devices';
import NoVerifiedMessage from './NoVerifiedMessage';
import SuccessMessage from './SuccessMessage';
import WaitingMessage from './WaitingMessage';

const FinishedStep = ({ callSettingsSkipped, device, deviceModalType }) => {
  let result = <SuccessMessage callSettingsSkipped={callSettingsSkipped} deviceModalType={deviceModalType} />;
  if (device) {
    if (device.verificationStatus === VERIFICATION_STATUS.PENDING) {
      result = <WaitingMessage callSettingsSkipped={callSettingsSkipped} deviceModalType={deviceModalType} />;
    } else if ([VERIFICATION_STATUS.NOT_VERIFIED, VERIFICATION_STATUS.ZOMBIE].includes(device.verificationStatus)) {
      result = <NoVerifiedMessage />;
    }
  }

  return <Box sx={{ textAlign: 'center' }}>{result}</Box>;
};

FinishedStep.propTypes = {
  callSettingsSkipped: PropTypes.bool,
  device: PropTypes.shape({
    deviceType: PropTypes.string,
    verificationStatus: PropTypes.string,
  }),
  deviceModalType: PropTypes.oneOf(['X2N', DEVICE_TYPE.HIPMO, DEVICE_TYPE.DOOR_STATION, DEVICE_TYPE.THIRD_PARTY]),
};

FinishedStep.defaultProps = {
  callSettingsSkipped: false,
  device: null,
  deviceModalType: null,
};

export default FinishedStep;
