// eslint-disable-next-line @typescript-eslint/no-var-requires
const landingBgImage = require('../../../assets/landing_bg.jpg');

export const styles = {
  box: {
    backgroundColor: 'background.paper',
    borderRadius: 1,
    boxSizing: 'border-box',
    padding: 3,
    width: '100%',
  },
  boxContent: {
    margin: '0 auto',
    maxWidth: '810px',
    padding: 1.25,
    width: '100%',
  },
  title: {
    color: '#fff',
    fontSize: '34px',
    fontWeight: 'bold',
    letterSpacing: 0.25,
    lineHeight: '42px',
    mb: 5,
    mt: {
      sm: 7,
      xl: 15.75,
    },
    textAlign: 'center',
  },
  wrapper: {
    backgroundImage: `url(${landingBgImage})`,
    backgroundPosition: '50% 20%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: '980px',
  },
};
