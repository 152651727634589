import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AccessLogCheckbox } from '../../../../components';
import messages from './messages';

const AccessUnitSetup = () => (
  <>
    <Typography variant="body2">
      <FormattedMessage {...messages.accessUnitSetupInfo} />
    </Typography>
    <AccessLogCheckbox />
  </>
);

export default AccessUnitSetup;
