import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AccessLogCheckbox, ConfigurationModeText, FormikFields } from '../../../../components';
import { CONFIGURATION_MODE, CONNECTION_MODE, DEVICE_TYPE } from '../../../../config/devices';
import { getTypeFromDeviceType } from '../../../../helpers/devices';
import messages from './messages';

const ConfigurationModeSetup = ({ configurationMode, deviceType, hasAccessControl }) => {
  const accessConnectionMode = () =>
    [DEVICE_TYPE.ID_COMPACT, DEVICE_TYPE.CLIP, DEVICE_TYPE.ID_TALK, DEVICE_TYPE.ID_VIEW].includes(
      getTypeFromDeviceType(deviceType)
    );

  return (
    <>
      <Typography sx={{ mb: 3 }}>
        <FormattedMessage {...messages.configurationModeSetupDescription} />
      </Typography>
      <Grid container spacing={2}>
        <Grid item sm={accessConnectionMode() ? 6 : 12} xs={12}>
          <FormikFields.Select
            helperText={<FormattedMessage {...messages.configurationModeSetupConfigurationModeHelperText} />}
            label={<FormattedMessage {...messages.configurationModeSetupConfigurationModeLabel} />}
            name="configurationMode"
            options={[
              {
                label: <FormattedMessage {...messages.configurationModeSetupConfigurationModeAutomatic} />,
                value: CONFIGURATION_MODE.AUTOMATIC,
              },
              {
                label: <FormattedMessage {...messages.configurationModeSetupConfigurationModeManual} />,
                value: CONFIGURATION_MODE.MANUAL,
              },
            ]}
            required
          />
        </Grid>
        {accessConnectionMode() && (
          <Grid item sm={6} xs={12}>
            <FormikFields.Select
              helperText={<FormattedMessage {...messages.configurationModeSetupConnectionModeHelperText} />}
              label={<FormattedMessage {...messages.configurationModeSetupConnectionModeLabel} />}
              name="connectionMode"
              options={[
                {
                  label: <FormattedMessage {...messages.configurationModeSetupConnectionModeCloud} />,
                  value: CONNECTION_MODE.CLOUD,
                },
                {
                  label: <FormattedMessage {...messages.configurationModeSetupConnectionModeLocal} />,
                  value: CONNECTION_MODE.LOCAL,
                },
              ]}
              required
            />
          </Grid>
        )}
      </Grid>
      {!accessConnectionMode() && hasAccessControl && <AccessLogCheckbox />}
      <ConfigurationModeText mode={configurationMode} />
    </>
  );
};

ConfigurationModeSetup.propTypes = {
  configurationMode: PropTypes.string.isRequired,
  deviceType: PropTypes.string.isRequired,
  hasAccessControl: PropTypes.bool.isRequired,
};

export default ConfigurationModeSetup;
