import PropTypes from 'prop-types';
import React from 'react';
import { DEVICE_TYPE, SERVICE } from '../../../../config/devices';
import { getTypeFromDeviceType } from '../../../../helpers/devices';
import AccessUnitSetup from './AccessUnitSetup';
import ConfigurationModeSetup from './ConfigurationModeSetup';
import IdtIdentifier from './IdtIdentifier';

const TwoStep = ({ deviceTypes, formProps, onSetNextStep }) => {
  const { values } = formProps;

  const hasAccessControlService = () => {
    const deviceType = getTypeFromDeviceType(values.deviceType);
    switch (deviceType) {
      case DEVICE_TYPE.ACCESS_UNIT:
      case DEVICE_TYPE.ACCESS_UNIT_M:
      case DEVICE_TYPE.CHAMELEON:
        return !!deviceTypes
          .find((item) => item.name === DEVICE_TYPE.ACCESS_UNIT)
          ?.template?.availableServices.includes(SERVICE.ACCESS_CONTROL);
      case DEVICE_TYPE.IP_INTERCOM:
      case DEVICE_TYPE.IP_INTERCOM_V2:
      case DEVICE_TYPE.IP_ONE_INTERCOM:
      case DEVICE_TYPE.IP_STYLE:
        return !!deviceTypes
          .find((item) => item.name === deviceType)
          ?.template?.availableServices.includes(SERVICE.ACCESS_CONTROL);
      default:
        return false;
    }
  };

  switch (getTypeFromDeviceType(values.deviceType)) {
    case DEVICE_TYPE.ACCESS_UNIT:
    case DEVICE_TYPE.ACCESS_UNIT_M:
    case DEVICE_TYPE.CHAMELEON:
      if (hasAccessControlService()) {
        return <AccessUnitSetup />;
      }
      onSetNextStep();
      return null;
    case DEVICE_TYPE.IDT:
      return <IdtIdentifier />;
    default:
      return (
        <ConfigurationModeSetup
          configurationMode={values.configurationMode}
          deviceType={values.deviceType}
          hasAccessControl={hasAccessControlService()}
        />
      );
  }
};

TwoStep.propTypes = {
  deviceTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  formProps: PropTypes.shape({
    isValid: PropTypes.bool,
    values: PropTypes.shape({
      configurationMode: PropTypes.string,
      deviceType: PropTypes.string,
    }),
  }).isRequired,
  onSetNextStep: PropTypes.func.isRequired,
};

export default TwoStep;
