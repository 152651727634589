import { FolderShared, Label } from '@mui/icons-material';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ApartmentAdminPinModal as ApartmentAdminPinModalComponent } from '../../../../../components/ApartmentAdminPinModal';
import { DataGridAggregated } from '../../../../../components/DataGridAggregated';
import { IRowAction } from '../../../../../components/DataGridAggregated/data';
import { useHasFeature } from '../../../../../components/Feature/Feature';
import featureFlags from '../../../../../config/featureFlags';
import useBreakpoints from '../../../../../helpers/useBreakpoints';
import { useModal } from '../../../../../hooks/useModal';
import { ApartmentUserWithMVStatus, IAggregatedApartmentUsers } from '../../../../../modules/apartmentAdmin/data';
import { getApartmentDevices } from '../../../../../modules/apartments/selectors';
import { IApartmentIdsInfo } from '../../../../../modules/apartments/store';
import { getApartmentIdsInfo } from "../../../../../modules/apartments/utils";
import { VisitorModalContext } from '../../../../../modules/commonTypes';
import { getUsersInApartmentsUserDetailPath } from '../../../../../routes/helpers/paths';
import { DeleteUserModal as DeleteUserModalComponent } from '../../../components/DeleteUserModal';
import { UWLDeleteUserModal as UWLDeleteUserModalComponent } from '../../../components/UWLDeleteUserModal';
import { UWLDeleteUserWithPINOnlyModal as UWLDeleteUserWithPINOnlyModalComponent } from '../../../components/UWLDeleteUserWithPINOnlyModal';
import { UWLDeleteUserWithRFIDOnlyModal as UWLDeleteUserWithRFIDOnlyModalComponent } from '../../../components/UWLDeleteUserWithRFIDOnlyModal';
import { openDeleteModalBasedOnUserType } from '../../../utils/userModalOpener';
import ApartmentAdminApartmentDetailModal from '../ApartmentAdminApartmentDetailModal/ApartmentAdminApartmentDetailModal';
import ChangeApartmentLabelModal from '../ChangeApartmentLabelModal';
import CreateUserDialog from '../CreateUserModal';
import VisitorModal from '../VisitorModal/VisitorModal';
import { getColumns } from './columns';
import { getDataGridFooterActions } from './footerActions';
import messages from './messages';
import { createUserRowMenuActions } from './rowMenuActions';

export interface IUsersInApartmentDataGridProps {
  apartment: IAggregatedApartmentUsers | undefined;
  isFetching?: boolean;
}

export function UsersInApartmentDataGrid({ apartment, isFetching }: IUsersInApartmentDataGridProps) {
  const { upLg, upSm } = useBreakpoints();
  const { formatMessage } = useIntl();
  const [userToDelete, setUserToDelete] = useState<ApartmentUserWithMVStatus | undefined>(undefined);
  const navigate = useNavigate();
  const isGuestAccessFFEnabled = useHasFeature(featureFlags.GUEST_ACCESS);
  const { Modal: ApartmentAdminPinModal, onOpen: openApartmentAdminPinModal } = useModal({
    Modal: ApartmentAdminPinModalComponent,
  });

  const { Modal: CreateUserModal, onOpen: onOpenCreateUserModal } = useModal({
    Modal: CreateUserDialog,
  });

  const { Modal: VisitorUModal, onOpen: onOpenVisitorUModal } = useModal({
    Modal: VisitorModal,
  });

  const { Modal: DeleteUserModal, onOpen: openDeleteUserModal } = useModal({
    Modal: DeleteUserModalComponent,
    onClose: () => {
      setUserToDelete(undefined);
    },
  });

  const { Modal: UWLDeleteUserWithPINOnlyModal, onOpen: openUWLDeleteUserWithPINOnlyModal } = useModal({
    Modal: UWLDeleteUserWithPINOnlyModalComponent,
    onClose: () => {
      setUserToDelete(undefined);
    },
  });

  const { Modal: UWLDeleteUserWithRFIDOnlyModal, onOpen: openUWLDeleteUserWithRFIDOnlyModal } = useModal({
    Modal: UWLDeleteUserWithRFIDOnlyModalComponent,
    onClose: () => {
      setUserToDelete(undefined);
    },
  });

  const { Modal: UWLDeleteUserModal, onOpen: openUWLDeleteUserModal } = useModal({
    Modal: UWLDeleteUserModalComponent,
    onClose: () => {
      setUserToDelete(undefined);
    },
  });

  const navigateToUserDetail = (user: ApartmentUserWithMVStatus, apartment: IAggregatedApartmentUsers) => {
    navigate(
      getUsersInApartmentsUserDetailPath({
        apartmentId: apartment.id,
        companyId: apartment.company.id,
        siteId: apartment.site.id,
        userId: user.id,
      })
    );
  };

  const { Modal: ApartmentDetailModal, onOpen: openApartmentDetailModal } = useModal({
    Modal: ApartmentAdminApartmentDetailModal,
  });

  const { Modal: ApartmentLabelModal, onOpen: openApartmentLabelModal } = useModal({
    Modal: ChangeApartmentLabelModal,
  });

  const apartmentDevices = useSelector(getApartmentDevices).data || [];
  const apartmentIdsInfo: IApartmentIdsInfo | undefined = apartment && getApartmentIdsInfo(apartment);

  return (
    <>
      <DataGridAggregated
        isFetching={isFetching}
        items={apartment ? { aggregatedParentItem: apartment, items: apartment.users } : undefined}
        columns={getColumns({ formatMessage, upLg, upSm })}
        onRowClick={apartment && ((user) => navigateToUserDetail(user, apartment))}
        onAggregatedRowClick={(_apartment) => openApartmentDetailModal()}
        footerActions={getDataGridFooterActions({
          isGuestAccessFFEnabled,
          onAddNewPin: () => {
            if (apartment) {
              openApartmentAdminPinModal();
            }
          },
          onAddNewUser: onOpenCreateUserModal,
          onAddNewVisitor: onOpenVisitorUModal,
        })}
        contextMenu={
          apartment && {
            aggregatedRowMenuActions: (_apartment: IAggregatedApartmentUsers) => [
              {
                icon: <FolderShared />,
                label: <FormattedMessage {...messages.AAUserListContextMenuShowApt} />,
                onClick: openApartmentDetailModal
              },
              {
                icon: <Label color="secondary" />,
                label: <FormattedMessage {...messages.AAUserListContextMenuChangeLabel} />,
                onClick: openApartmentLabelModal
              },
            ],
            rowMenuActions: (user: ApartmentUserWithMVStatus) => getRowMenuActions(user, apartment)
          }
        }
      />

      {apartmentIdsInfo && (
        <>
          <ApartmentDetailModal apartmentIdsInfo={apartmentIdsInfo} apartmentLabelModal={openApartmentLabelModal}/>
          <ApartmentLabelModal apartmentIdsInfo={apartmentIdsInfo} contactLabels={apartment?.contactLabels} />
          <CreateUserModal
            apartmentInfo={apartmentIdsInfo}
            paidDevicesCount={apartment?.paidDevicesCount}
            paidDevicesLimit={apartment?.paidDevicesLimit}
            isUnlicensed={apartment?.apartmentMobileVideoState === 'UNLICENSED'}
          />
          <ApartmentAdminPinModal apartmentInfo={apartmentIdsInfo} />
          <VisitorUModal
            msgContext={VisitorModalContext.APARTMENT_USERS_NEW_GUESTMODAL}
            apartmentInfo={apartmentIdsInfo}
            siteName={apartment?.site.name}
            apartmentDevices={apartmentDevices}
          />
        </>
      )}

      {userToDelete && apartmentIdsInfo && (
        <>
          <DeleteUserModal user={userToDelete} apartmentIdsInfo={apartmentIdsInfo} />
          <UWLDeleteUserWithPINOnlyModal user={userToDelete} apartmentIdsInfo={apartmentIdsInfo} />
          <UWLDeleteUserWithRFIDOnlyModal user={userToDelete} apartmentIdsInfo={apartmentIdsInfo} />
          <UWLDeleteUserModal user={userToDelete} apartmentIdsInfo={apartmentIdsInfo} />
        </>
      )}
    </>
  );

  function getRowMenuActions(user: ApartmentUserWithMVStatus, apartment: IAggregatedApartmentUsers): IRowAction[] {
    const onDeleteUser = () => {
      setUserToDelete(user);
      openDeleteModalBasedOnUserType({
        openDeleteUserModal,
        openUWLDeleteUserModal,
        openUWLDeleteUserWithPINOnlyModal,
        openUWLDeleteUserWithRFIDOnlyModal,
        user,
      });
    };

    const onEditUser = () => {
      navigateToUserDetail(user, apartment);
    };

    return createUserRowMenuActions({
      formatMessage,
      onDeleteUser,
      onEditUser,
      user,
    });
  }
}
