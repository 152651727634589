import { DEVICE_TYPE } from '../../config/devices';
import actions from '../../modules/devices';

export const add2NDevice = (values, formActions, floors, onStep) =>
  actions.addDevice(values, 'X2N', formActions, floors, onStep);

export const addAxisDoorStationDevice = (values, formActions) =>
  actions.addDevice(values, DEVICE_TYPE.DOOR_STATION, formActions);

export const addHipmoDevice = (values, formActions, floors) =>
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  actions.addDevice(values, DEVICE_TYPE.HIPMO, formActions, floors, () => {});

export const addThirdPartyDevice = (values, formActions, floors) =>
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  actions.addDevice(values, DEVICE_TYPE.THIRD_PARTY, formActions, floors, () => {});
