import { defineMessages } from 'react-intl';

export default defineMessages({
  AAUserDetailAccessTitle: {
    defaultMessage: 'Access settings',
    id: 'AA.userDetail.access.title',
  },
  AAUserDetailTitle: {
    defaultMessage: 'User basic info',
    id: 'AA.userDetail.title',
  },
});
