import { defineMessages } from 'react-intl';

export default defineMessages({
  x2NDeviceFormAddTitle: {
    defaultMessage: 'Add 2N Device',
    description: 'DeviceTitle - Add 2N Device',
    id: 'X2NDeviceForm.addTitle',
  },
  x2NDeviceFormApartmentNumberExists: {
    defaultMessage: 'An apartment with this number already exists.',
    description: 'Device Modal - new apartment form - apartment number already exists',
    id: 'X2NDeviceForm.apartment.numberExists',
  },
  x2NDeviceFormIdentifierNoValid: {
    defaultMessage: '0-9", "a-zA-Z", "-',
    description: 'Device Modal - IDT identifier validation',
    id: 'X2NDeviceForm.identifier.noValid',
  },
  x2NDeviceFormNameExist: {
    defaultMessage: 'This device name is already used.',
    description: 'Device Modal - name exist',
    id: 'X2NDeviceForm.name.exist',
  },
  x2NDeviceFormRequired: {
    defaultMessage: 'Value is mandatory',
    description: 'Device Modal - required value',
    id: 'X2NDeviceForm.required',
  },
  x2NDeviceFormSecurityCodeNoValid: {
    defaultMessage: 'Please enter My2N Security Code in valid format.',
    description: 'Device Modal 2N - security code no valid',
    id: 'X2NDeviceForm.securityCode.noValid',
  },
  x2NDeviceFormSerialNumberNoValid: {
    defaultMessage: 'Please enter serial number in valid format.',
    description: 'Device Modal 2N - serial number no valid',
    id: 'X2NDeviceForm.serialNumber.noValid',
  },
  x2NDeviceFormTitle: {
    defaultMessage: '2N Device',
    description: 'devices - 2N device',
    id: 'X2NDeviceForm.title',
  },
  x2NDeviceFormTooLong: {
    defaultMessage: 'Value is too long',
    description: 'Device Modal - value is too long',
    id: 'X2NDeviceForm.tooLong',
  },
});
