import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { connect, getIn } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormikFields } from '../../../../components';
import { isIdt2 } from '../../../../helpers/devices';
import messages from './messages';

export const FAQ_IDT = 'https://faq.2n.cz/pages/viewpage.action?pageId=85885089';

const IdtIdentifier = ({ formik }) => {
  const { values } = formik;
  const isTypeIdt2 = useMemo(() => isIdt2(getIn(values, 'deviceType')), [values]);
  return (
    <>
      <Typography paragraph variant="body2">
        <FormattedMessage
          {...(isTypeIdt2 ? messages.idtIdentifierDescriptionIdt2 : messages.idtIdentifierDescriptionIdt1)}
          values={{
            br: <br />,
            sup: (chunks) => <sup>{chunks}</sup>,
          }}
        />
      </Typography>
      <FormikFields.Input
        helperText={
          <FormattedMessage {...(isTypeIdt2 ? messages.idtIdentifierExampleIdt2 : messages.idtIdentifierExampleIdt1)} />
        }
        label={
          <FormattedMessage {...(isTypeIdt2 ? messages.idtIdentifierLabelIdt2 : messages.idtIdentifierLabelIdt1)} />
        }
        name="identifier"
      />
      {isTypeIdt2 && (
        <Typography sx={{ mt: 1 }} variant="body2">
          <FormattedMessage
            {...messages.idtIdentifierFaq}
            values={{
              url: (chunks) => (
                <Link href={FAQ_IDT} rel="noopener noreferrer" target="_blank">
                  {chunks}
                </Link>
              ),
            }}
          />
        </Typography>
      )}
    </>
  );
};

IdtIdentifier.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      deviceType: PropTypes.string,
    }),
  }).isRequired,
};

export default connect(IdtIdentifier);
