import { api } from './api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    apartmentAdminDeleteApartmentUserById: build.mutation<
      ApartmentAdminDeleteApartmentUserByIdApiResponse,
      ApartmentAdminDeleteApartmentUserByIdApiArg
    >({
      query: (queryArg) => ({
        method: 'DELETE',
        url: `/fe/apartment-admin/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments/${queryArg.apartmentId}/users/${queryArg.userId}`,
      }),
    }),
    bulkCreateLoginLessUser: build.mutation<BulkCreateLoginLessUserApiResponse, BulkCreateLoginLessUserApiArg>({
      query: (queryArg) => ({
        body: queryArg.loginLessUserBulkRequest,
        method: 'POST',
        url: `/fe/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments/${queryArg.apartmentId}/bulk-login-less`,
      }),
    }),
    checkUserExistenceInSiteOfApartment: build.mutation<
      CheckUserExistenceInSiteOfApartmentApiResponse,
      CheckUserExistenceInSiteOfApartmentApiArg
    >({
      query: (queryArg) => ({
        method: 'HEAD',
        params: { email: queryArg.email },
        url: `/fe/apartment-admin/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments/${queryArg.apartmentId}/users`,
      }),
    }),
    countContactButtonsForDevice: build.query<
      CountContactButtonsForDeviceApiResponse,
      CountContactButtonsForDeviceApiArg
    >({
      query: (queryArg) => ({
        url: `/fe/companies/${queryArg.companyId}/sites/${queryArg.siteId}/devices/${queryArg.deviceId}/contact-buttons/count`,
      }),
    }),
    createGuestAndAssignPinInApartment: build.mutation<
      CreateGuestAndAssignPinInApartmentApiResponse,
      CreateGuestAndAssignPinInApartmentApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.createGuestAssignPinRequest,
        method: 'POST',
        url: `/fe/apartment-admin/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments/${queryArg.apartmentId}/create-guest-assign-pin`,
      }),
    }),
    createGuestAndAssignPinInSite: build.mutation<
      CreateGuestAndAssignPinInSiteApiResponse,
      CreateGuestAndAssignPinInSiteApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.createGuestAssignPinRequest,
        method: 'POST',
        url: `/fe/companies/${queryArg.companyId}/sites/${queryArg.siteId}/create-guest-assign-pin`,
      }),
    }),
    createLoginLessAndAssignPinInApartment: build.mutation<
      CreateLoginLessAndAssignPinInApartmentApiResponse,
      CreateLoginLessAndAssignPinInApartmentApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.createLoginLessAssignPinRequest,
        method: 'POST',
        url: `/fe/apartment-admin/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments/${queryArg.apartmentId}/create-user-with-pin`,
      }),
    }),
    createSiteUser: build.mutation<CreateSiteUserApiResponse, CreateSiteUserApiArg>({
      query: (queryArg) => ({
        body: queryArg.siteUserRequestImpl,
        method: 'POST',
        url: `/fe/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users`,
      }),
    }),
    createUserDevice: build.mutation<CreateUserDeviceApiResponse, CreateUserDeviceApiArg>({
      query: (queryArg) => ({ body: queryArg.userDeviceCreateRequest, method: 'POST', url: `/composite/user` }),
    }),
    createUserInApartmentOfApartmentAdmin: build.mutation<
      CreateUserInApartmentOfApartmentAdminApiResponse,
      CreateUserInApartmentOfApartmentAdminApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.createUserInApartmentRequest,
        method: 'POST',
        url: `/fe/apartment-admin/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments/${queryArg.apartmentId}/users`,
      }),
    }),
    deleteApartmentUserById: build.mutation<DeleteApartmentUserByIdApiResponse, DeleteApartmentUserByIdApiArg>({
      query: (queryArg) => ({
        method: 'DELETE',
        url: `/fe/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments/${queryArg.apartmentId}/users/${queryArg.userId}`,
      }),
    }),
    deleteSiteUserById: build.mutation<DeleteSiteUserByIdApiResponse, DeleteSiteUserByIdApiArg>({
      query: (queryArg) => ({
        method: 'DELETE',
        url: `/fe/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}`,
      }),
    }),
    editGuestInApartment: build.mutation<EditGuestInApartmentApiResponse, EditGuestInApartmentApiArg>({
      query: (queryArg) => ({
        body: queryArg.editGuestRequest,
        method: 'PUT',
        url: `/fe/apartment-admin/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments/${queryArg.apartmentId}/edit-guest/${queryArg.userId}`,
      }),
    }),
    editGuestInSite: build.mutation<EditGuestInSiteApiResponse, EditGuestInSiteApiArg>({
      query: (queryArg) => ({
        body: queryArg.editGuestRequest,
        method: 'PUT',
        url: `/fe/companies/${queryArg.companyId}/sites/${queryArg.siteId}/edit-guest/${queryArg.userId}`,
      }),
    }),
    elevatorIntegrationFloorMapping: build.query<
      ElevatorIntegrationFloorMappingApiResponse,
      ElevatorIntegrationFloorMappingApiArg
    >({
      query: (queryArg) => ({
        url: `/fe/companies/${queryArg.companyId}/sites/${queryArg.siteId}/elevator-integration/floor-mapping`,
      }),
    }),
    getApartmentDetailForAa: build.query<GetApartmentDetailForAaApiResponse, GetApartmentDetailForAaApiArg>({
      query: (queryArg) => ({
        url: `/fe/apartment-admin/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments/${queryArg.apartmentId}`,
      }),
    }),
    getApartmentUserForAaById: build.query<GetApartmentUserForAaByIdApiResponse, GetApartmentUserForAaByIdApiArg>({
      query: (queryArg) => ({
        url: `/fe/apartment-admin/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments/${queryArg.apartmentId}/users/${queryArg.userId}`,
      }),
    }),
    getCreditCostUntilEndOfMonth: build.query<
      GetCreditCostUntilEndOfMonthApiResponse,
      GetCreditCostUntilEndOfMonthApiArg
    >({
      query: (queryArg) => ({
        params: { licencesCount: queryArg.licencesCount },
        url: `/fe/companies/${queryArg.companyId}/sites/${queryArg.siteId}/services/MOBILE_VIDEO/licence/standardModel/creditCostUntilEndOfMonth`,
      }),
    }),
    getSite: build.query<GetSiteApiResponse, GetSiteApiArg>({
      query: (queryArg) => ({ url: `/fe/companies/${queryArg.companyId}/sites/${queryArg.siteId}` }),
    }),
    getSiteUserById: build.query<GetSiteUserByIdApiResponse, GetSiteUserByIdApiArg>({
      query: (queryArg) => ({
        url: `/fe/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}`,
      }),
    }),
    listApartmentUsers: build.query<ListApartmentUsersApiResponse, ListApartmentUsersApiArg>({
      query: (queryArg) => ({
        params: { filter: queryArg.filter, limit: queryArg.limit, offset: queryArg.offset, order: queryArg.order },
        url: `/fe/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments/${queryArg.apartmentId}/users`,
      }),
    }),
    listApartmentUsersByApartmentAdminGroupedByApartment: build.query<
      ListApartmentUsersByApartmentAdminGroupedByApartmentApiResponse,
      ListApartmentUsersByApartmentAdminGroupedByApartmentApiArg
    >({
      query: () => ({ url: `/fe/apartment-admin/users-in-apartments` }),
    }),
    listContactButtonsForDevice: build.query<ListContactButtonsForDeviceApiResponse, ListContactButtonsForDeviceApiArg>(
      {
        query: (queryArg) => ({
          params: { limit: queryArg.limit, offset: queryArg.offset },
          url: `/fe/companies/${queryArg.companyId}/sites/${queryArg.siteId}/devices/${queryArg.deviceId}/contact-buttons`,
        }),
      }
    ),
    listGroupUsers: build.query<ListGroupUsersApiResponse, ListGroupUsersApiArg>({
      query: (queryArg) => ({
        params: { limit: queryArg.limit, offset: queryArg.offset, order: queryArg.order },
        url: `/fe/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups/${queryArg.groupId}/users`,
      }),
    }),
    listSiteUsers: build.query<ListSiteUsersApiResponse, ListSiteUsersApiArg>({
      query: (queryArg) => ({
        params: { filter: queryArg.filter, limit: queryArg.limit, offset: queryArg.offset, order: queryArg.order },
        url: `/fe/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users`,
      }),
    }),
    listSiteUsersWithGroups: build.query<ListSiteUsersWithGroupsApiResponse, ListSiteUsersWithGroupsApiArg>({
      query: (queryArg) => ({ url: `/fe/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users-with-groups` }),
    }),
    modifySiteUserById: build.mutation<ModifySiteUserByIdApiResponse, ModifySiteUserByIdApiArg>({
      query: (queryArg) => ({
        body: queryArg.siteUserWithLocaleRequest,
        method: 'PUT',
        url: `/fe/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}`,
      }),
    }),
    updateApartmentLabel: build.mutation<UpdateApartmentLabelApiResponse, UpdateApartmentLabelApiArg>({
      query: (queryArg) => ({
        body: queryArg.apartmentLabelRequest,
        method: 'PUT',
        url: `/fe/apartment-admin/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments/${queryArg.apartmentId}/apartment-label`,
      }),
    }),
    updateUserBasicInfoInApartment: build.mutation<
      UpdateUserBasicInfoInApartmentApiResponse,
      UpdateUserBasicInfoInApartmentApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.userModifyNameRequest,
        method: 'PUT',
        url: `/fe/apartment-admin/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments/${queryArg.apartmentId}/users/${queryArg.userId}`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as be4feApi };
export type GetSiteUserByIdApiResponse = /** status 200 successful operation */ SiteUserWithLocaleResponse;
export type GetSiteUserByIdApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  /** id of the User to perform action with. */
  userId: number;
};
export type ModifySiteUserByIdApiResponse = /** status 200 successful operation */ SiteUserWithLocaleResponse;
export type ModifySiteUserByIdApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  /** id of the User to perform action with. */
  userId: number;
  siteUserWithLocaleRequest: SiteUserWithLocaleRequest;
};
export type DeleteSiteUserByIdApiResponse = unknown;
export type DeleteSiteUserByIdApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  /** id of the User to perform action with. */
  userId: number;
};
export type EditGuestInSiteApiResponse = unknown;
export type EditGuestInSiteApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  /** id of the User to perform action with. */
  userId: number;
  editGuestRequest: EditGuestRequest;
};
export type GetApartmentUserForAaByIdApiResponse =
  /** status 200 successful operation */ SiteUserWithLocaleAndApartmentResponse;
export type GetApartmentUserForAaByIdApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  /** Numeric apartmentId of the Apartment to perform action with. */
  apartmentId: number;
  /** id of the User to perform action with. */
  userId: number;
};
export type UpdateUserBasicInfoInApartmentApiResponse =
  /** status 200 successful operation */ UpdateUserBasicInfoInApartmentResponse;
export type UpdateUserBasicInfoInApartmentApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  /** Numeric apartmentId of the Apartment to perform action with. */
  apartmentId: number;
  /** id of the User to perform action with. */
  userId: number;
  userModifyNameRequest: UserModifyNameRequest;
};
export type ApartmentAdminDeleteApartmentUserByIdApiResponse = unknown;
export type ApartmentAdminDeleteApartmentUserByIdApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  /** Numeric apartmentId of the Apartment to perform action with. */
  apartmentId: number;
  /** id of the User to perform action with. */
  userId: number;
};
export type EditGuestInApartmentApiResponse = unknown;
export type EditGuestInApartmentApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  /** Numeric apartmentId of the Apartment to perform action with. */
  apartmentId: number;
  /** id of the User to perform action with. */
  userId: number;
  editGuestRequest: EditGuestRequest;
};
export type UpdateApartmentLabelApiResponse = unknown;
export type UpdateApartmentLabelApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  /** Numeric apartmentId of the Apartment to perform action with. */
  apartmentId: number;
  apartmentLabelRequest: ApartmentLabelRequest;
};
export type ListSiteUsersApiResponse = /** status 200 successful operation */ SiteUserList;
export type ListSiteUsersApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  /** Text to filter the results with. Filter columns [id, uuid, email, firstName, lastName] */
  filter?: string;
  /** Maximum number of records to be returned. Default 50, maximum 1000 */
  limit?: number;
  /** Name of the attribute to sort the results with. If it is prepended with '-', the order is reversed. Order columns [name, email, firstName, lastName, dateCreated, enabled]  */
  order?: string[];
  /** Return the results with this offset, first record has zero offset. */
  offset?: number;
};
export type CreateSiteUserApiResponse = /** status 200 successful operation */ SiteUserWithLocaleResponse;
export type CreateSiteUserApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  siteUserRequestImpl: SiteUserRequestImpl;
};
export type CreateGuestAndAssignPinInSiteApiResponse = unknown;
export type CreateGuestAndAssignPinInSiteApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  createGuestAssignPinRequest: CreateGuestAssignPinRequest;
};
export type BulkCreateLoginLessUserApiResponse = unknown;
export type BulkCreateLoginLessUserApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  /** Numeric apartmentId of the Apartment to perform action with. */
  apartmentId: number;
  loginLessUserBulkRequest: LoginLessUserBulkRequest;
};
export type CreateUserInApartmentOfApartmentAdminApiResponse =
  /** status 200 successful operation */ CreateUserInApartmentResponse;
export type CreateUserInApartmentOfApartmentAdminApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  /** Numeric apartmentId of the Apartment to perform action with. */
  apartmentId: number;
  createUserInApartmentRequest: CreateUserInApartmentRequest;
};
export type CheckUserExistenceInSiteOfApartmentApiResponse = unknown;
export type CheckUserExistenceInSiteOfApartmentApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  /** Numeric apartmentId of the Apartment to perform action with. */
  apartmentId: number;
  /** Return the email query param. */
  email?: string;
};
export type CreateLoginLessAndAssignPinInApartmentApiResponse = unknown;
export type CreateLoginLessAndAssignPinInApartmentApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  /** Numeric apartmentId of the Apartment to perform action with. */
  apartmentId: number;
  createLoginLessAssignPinRequest: CreateLoginLessAssignPinRequest;
};
export type CreateGuestAndAssignPinInApartmentApiResponse = unknown;
export type CreateGuestAndAssignPinInApartmentApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  /** Numeric apartmentId of the Apartment to perform action with. */
  apartmentId: number;
  createGuestAssignPinRequest: CreateGuestAssignPinRequest;
};
export type CreateUserDeviceApiResponse = /** status 200 Success */ UserDeviceCreateResponse;
export type CreateUserDeviceApiArg = {
  userDeviceCreateRequest: UserDeviceCreateRequest;
};
export type ListSiteUsersWithGroupsApiResponse = /** status 200 successful operation */ UserWithGroupsResponse[];
export type ListSiteUsersWithGroupsApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
};
export type GetCreditCostUntilEndOfMonthApiResponse = /** status 200 successful operation */ CreditCost;
export type GetCreditCostUntilEndOfMonthApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  /** Number of licences to estimate credit cost for. If omitted, the number of devices/apartments not covered by existing licences is used. */
  licencesCount?: number;
};
export type ListGroupUsersApiResponse = /** status 200 successful operation */ GroupUsers;
export type ListGroupUsersApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  /** id of the group */
  groupId: string;
  /** Maximum number of records to be returned. Default 50, maximum 1000 */
  limit?: number;
  /** Name of the attribute to sort the results with. If it is prepended with '-', the order is reversed. Order columns [name, email, firstName, lastName, dateCreated, enabled]  */
  order?: string[];
  /** Return the results with this offset, first record has zero offset. */
  offset?: number;
};
export type ElevatorIntegrationFloorMappingApiResponse =
  /** status 200 successful operation */ ElIntEnrichedFloorMapping;
export type ElevatorIntegrationFloorMappingApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
};
export type CountContactButtonsForDeviceApiResponse = /** status 200 number of buttons */ number;
export type CountContactButtonsForDeviceApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  /** Numeric id of a Device to perform action with. */
  deviceId: number;
};
export type ListContactButtonsForDeviceApiResponse =
  /** status 200 list of buttons with contacts */ ButtonsWithContactsResponse;
export type ListContactButtonsForDeviceApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  /** Numeric id of a Device to perform action with. */
  deviceId: number;
  /** Maximum number of records to be returned. Default 50, maximum 1000 */
  limit?: number;
  /** Return the results with this offset, first record has zero offset. */
  offset?: number;
};
export type ListApartmentUsersApiResponse = /** status 200 successful operation */ ApartmentUserList;
export type ListApartmentUsersApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  /** Numeric apartmentId of the Apartment to perform action with. */
  apartmentId: number;
  /** Text to filter the results with. Filter columns [id, uuid, email, firstName, lastName] */
  filter?: string;
  /** Maximum number of records to be returned. Default 50, maximum 1000 */
  limit?: number;
  /** Name of the attribute to sort the results with. If it is prepended with '-', the order is reversed. Order columns [name, email, firstName, lastName, dateCreated, enabled]  */
  order?: string[];
  /** Return the results with this offset, first record has zero offset. */
  offset?: number;
};
export type GetSiteApiResponse = /** status 200 successful operation */ SiteWithGuestPinSettingsResponse;
export type GetSiteApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
};
export type ListApartmentUsersByApartmentAdminGroupedByApartmentApiResponse =
  /** status 200 successful operation */ ApartmentInfoWithUsersList[];
export type ListApartmentUsersByApartmentAdminGroupedByApartmentApiArg = void;
export type GetApartmentDetailForAaApiResponse = /** status 200 successful operation */ ApartmentDetailResponse;
export type GetApartmentDetailForAaApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  /** Numeric apartmentId of the Apartment to perform action with. */
  apartmentId: number;
};
export type DeleteApartmentUserByIdApiResponse = unknown;
export type DeleteApartmentUserByIdApiArg = {
  /** Numeric companyId of the Company to perform action with. */
  companyId: number;
  /** Numeric siteId of the Site to perform action with. */
  siteId: number;
  /** Numeric apartmentId of the Apartment to perform action with. */
  apartmentId: number;
  /** id of the User to perform action with. */
  userId: number;
};
export type UserRole = 'SITE_USER' | 'SITE_ADMIN' | 'SITE_ADMIN_PAYMENT' | 'COMPANY_ADMIN' | 'LOGIN_LESS' | 'GUEST';
export type SiteUserWithLocaleResponse = {
  firstName?: string;
  lastName: string;
  email?: string;
  role: UserRole;
  apartments: number[];
  id: number;
  uuid: string;
  locale: string;
  enabled: boolean;
};
export type SiteUserWithLocaleRequest = {
  firstName?: string;
  lastName: string;
  email?: string;
  role: UserRole;
  apartments: number[];
  locale: string;
};
export type EditGuestRequest = {
  pin: string;
  lastName: string;
  validTo?: string;
  validFrom?: string;
  site?: string;
  emails?: string[];
  apartmentAccess?: boolean;
};
export type ApartmentUserRole =
  | 'APARTMENT_ADMIN_INHERITED'
  | 'APARTMENT_ADMIN'
  | 'APARTMENT_USER'
  | 'APARTMENT_LOGIN_LESS'
  | 'APARTMENT_GUEST';
export type RacCredentialsTypes = 'PIN' | 'RFID' | 'BT' | 'LPR';
export type ApartmentUserWithCredentials = {
  firstName?: string;
  lastName: string;
  email?: string;
  id: number;
  uuid: string;
  role: ApartmentUserRole;
  locale: string;
  enabled: boolean;
  validFrom?: string;
  validTo?: string;
  credentials: RacCredentialsTypes[];
};
export type ApartmentMobileVideoState =
  | 'ACTIVE_FOR_FREE'
  | 'NO_SERVICE'
  | 'ACTIVE_SUBSCRIPTIONS'
  | 'UNLICENSED'
  | 'OTHER';
export type ApartmentWithMobileVideoStateAndDevicesCount = {
  id: number;
  name?: string;
  number: string;
  devicesCount: number;
  paidDevicesCount: number;
  paidDevicesLimit: number;
  paidServicesActive?: boolean;
  apartmentMobileVideoState: ApartmentMobileVideoState;
};
export type SiteUserWithLocaleAndApartmentResponse = {
  user: ApartmentUserWithCredentials;
  apartment: ApartmentWithMobileVideoStateAndDevicesCount;
};
export type UpdateUserBasicInfoInApartmentResponse = {
  firstName?: string;
  lastName: string;
  email?: string;
  id: number;
  uuid: string;
  role: ApartmentUserRole;
  locale: string;
  enabled: boolean;
  validFrom?: string;
  validTo?: string;
  credentials: RacCredentialsTypes[];
};
export type UserModifyNameRequest = {
  firstName?: string;
  lastName: string;
};
export type ApartmentLabelRequest = {
  label: string;
};
export type Apartment = {
  id: number;
  name?: string;
  number: string;
};
export type UserWithApartmentsAndCredentials = {
  firstName?: string;
  lastName: string;
  email?: string;
  role: UserRole;
  id: number;
  uuid: string;
  apartments: Apartment[];
  enabled: boolean;
  validFrom?: string;
  validTo?: string;
  credentials: RacCredentialsTypes[];
};
export type SiteUserList = {
  resultsFiltered: number;
  resultsTotal: number;
  offset: number;
  limit: number;
  users: UserWithApartmentsAndCredentials[];
};
export type SiteUserRequestImpl = {
  firstName?: string;
  lastName: string;
  email?: string;
  role: UserRole;
  apartments: number[];
};
export type CreateGuestAssignPinRequest = {
  pin: string;
  lastName: string;
  validTo?: string;
  validFrom?: string;
  site?: string;
  emails?: string[];
  apartmentAccess?: boolean;
};
export type LoginLessUser = {
  lastName: string;
};
export type LoginLessUserBulkRequest = {
  generatePin: boolean;
  users: LoginLessUser[];
};
export type CreateUserInApartmentResponse = {
  firstName?: string;
  lastName: string;
  email?: string;
  id: number;
  uuid: string;
  role: ApartmentUserRole;
  locale: string;
  enabled: boolean;
  validFrom?: string;
  validTo?: string;
  credentials: RacCredentialsTypes[];
};
export type CreateUserInApartmentRequest = {
  firstName?: string;
  lastName: string;
  email?: string;
  role: UserRole;
  mobileVideoActivated: boolean;
};
export type CreateLoginLessAssignPinRequest = {
  pin: string;
  lastName: string;
};
export type IdentifiedLong = {
  unknownFields?: {
    [key: string]: object;
  };
  id?: number;
};
export type ContactItemTypes = 'RINGING_GROUP' | 'LOCAL_CALLS' | 'GSM_CALLS' | 'MOBILE_VIDEO' | 'APARTMENT';
export type GsmFallback = {
  deviceConfigId?: number;
  gatewayId?: number;
};
export type ContactItem = {
  id: number;
  externalId?: string;
  type?: ContactItemTypes;
  sipNumber?: string;
  apartmentId?: number;
  members?: number[];
  gsmFallback?: GsmFallback;
};
export type Contact = {
  id: number;
  externalId?: string;
  name?: string;
  virtualNumber?: string;
  contactItems: ContactItem[];
};
export type Feature = {
  unknownFields?: {
    [key: string]: object;
  };
  id?: number;
  apartmentId?: number;
  userId?: number;
  name?: string;
  type?: string;
  contacts?: Contact[];
};
export type Device = {
  unknownFields?: {
    [key: string]: object;
  };
  id?: number;
  apartmentId?: number;
  userId?: number;
  name?: string;
  type?: string;
  features?: Feature[];
};
export type SystemRole =
  | 'SUPERADMIN'
  | 'CUSTOMER_CARE'
  | 'COMPANY_CREATOR'
  | 'ACTIVATION_CODE_CREATOR'
  | 'MARKETING'
  | 'MAILQ';
export type CompanyRole = 'STANDARD' | 'COMPANY_ADMIN' | 'GREEN_ADMIN' | 'MOBILE_VIDEO_BUYER' | 'IP_INTERCOM_BUYER';
export type SiteRole = 'USER' | 'ADMIN';
export type ApartmentRole = 'RESIDENT' | 'APARTMENT_ADMIN';
export type ApartmentDetail = {
  unknownFields?: {
    [key: string]: object;
  };
  id?: number;
  name?: string;
  number?: string;
  roles?: ApartmentRole[];
};
export type SiteDetail = {
  unknownFields?: {
    [key: string]: object;
  };
  id?: number;
  roles?: SiteRole[];
  apartments?: ApartmentDetail[];
};
export type CompanyDetail = {
  unknownFields?: {
    [key: string]: object;
  };
  id?: number;
  roles?: CompanyRole[];
  sites?: SiteDetail[];
};
export type UserType = 'USER' | 'LOGIN_LESS' | 'GUEST';
export type AcGroupDto = {
  id?: string;
  name?: string;
  description?: string;
  siteId?: string;
};
export type AcRacCredentialsTypes = 'PIN' | 'RFID' | 'BT' | 'LPR';
export type ComposedUser = {
  unknownFields?: {
    [key: string]: object;
  };
  roles?: SystemRole[];
  companies?: CompanyDetail[];
  type?: UserType;
  firstName?: string;
  lastName?: string;
  locale?: {
    language?: string;
    displayName?: string;
    country?: string;
    variant?: string;
    script?: string;
    unicodeLocaleAttributes?: string[];
    unicodeLocaleKeys?: string[];
    displayLanguage?: string;
    displayScript?: string;
    displayCountry?: string;
    displayVariant?: string;
    extensionKeys?: string[];
    iso3Language?: string;
    iso3Country?: string;
  };
  groups?: AcGroupDto[];
  uuid?: string;
  email?: string;
  id?: number;
  enabled?: boolean;
  validFrom?: string;
  validTo?: string;
  credentials?: AcRacCredentialsTypes[];
};
export type UserDeviceCreateResponse = {
  floor?: IdentifiedLong;
  apartment?: Apartment;
  device?: Device;
  user?: ComposedUser;
};
export type ApiExceptionDto = {
  code?: string;
  message?: string;
};
export type CompanyMapping = {
  companyId: number;
  types: CompanyRole[];
};
export type SiteMapping = {
  siteId: number;
  types: SiteRole[];
};
export type ApartmentMapping = {
  apartmentId: number;
  types: ApartmentRole[];
};
export type RoleMapping = {
  systemRoles?: SystemRole[];
  companyRoles?: CompanyMapping[];
  siteRoles?: SiteMapping[];
  apartmentRoles?: ApartmentMapping[];
};
export type Terms = {
  url?: string;
  fileName?: string;
};
export type UserRequest = {
  unknownFields?: {
    [key: string]: object;
  };
  type?: UserType;
  email?: string;
  firstName?: string;
  lastName?: string;
  grantedRoles?: RoleMapping;
  terms?: Terms;
  siteName?: string;
  locale?: {
    language?: string;
    displayName?: string;
    country?: string;
    variant?: string;
    script?: string;
    unicodeLocaleAttributes?: string[];
    unicodeLocaleKeys?: string[];
    displayLanguage?: string;
    displayScript?: string;
    displayCountry?: string;
    displayVariant?: string;
    extensionKeys?: string[];
    iso3Language?: string;
    iso3Country?: string;
  };
  notificationId?: number;
};
export type UserDeviceCreateRequest = {
  floor?: object;
  apartment?: Apartment;
  device: Device;
  user: UserRequest;
  companyId: number;
  siteId: number;
};
export type UserWithGroupsResponse = {
  firstName?: string;
  lastName: string;
  email?: string;
  role: UserRole;
  id: number;
  uuid: string;
  apartments: Apartment[];
  enabled: boolean;
  groups: string[];
};
export type CreditCost = {
  creditsNeeded: number;
  licencesCount: number;
};
export type Group = {
  description?: string;
  id: string;
  name: string;
};
export type UserWithApartmentsAndGroupsAndCredentials = {
  firstName?: string;
  lastName: string;
  email?: string;
  role: UserRole;
  id: number;
  uuid: string;
  apartments: Apartment[];
  enabled: boolean;
  validFrom?: string;
  validTo?: string;
  credentials: RacCredentialsTypes[];
  groups: Group[];
};
export type GroupUsers = {
  groupUsers?: UserWithApartmentsAndGroupsAndCredentials[];
  resultsFiltered: number;
  resultsTotal: number;
  offset: number;
  limit: number;
};
export type ElIntDestination = {
  areaId: string;
  shortName: string;
};
export type ElIntElevatorGroup = {
  id: string;
  name: string;
  destinations: ElIntDestination[];
};
export type ElIntBuilding = {
  id: string;
  elevatorGroups: ElIntElevatorGroup[];
};
export type ElIntBuildingTopology = {
  buildings: ElIntBuilding[];
};
export type ElIntFloor = {
  id: string;
  name: string;
};
export type ElIntStop = {
  buildingId: string;
  elevatorGroupId: string;
  areaId: string;
};
export type ElIntSingleFloorMapping = {
  floorId: string;
  mappings: ElIntStop[];
};
export type ElIntFloorMapping = {
  floors: ElIntSingleFloorMapping[];
};
export type ElIntEnrichedFloorMapping = {
  buildingTopology: ElIntBuildingTopology;
  floors: ElIntFloor[];
  floorMapping: ElIntFloorMapping;
};
export type ButtonWithContactsResponse = {
  externalId?: string;
  name?: string;
  idx?: number;
  displayButtonId?: number;
  buttonConfigurationButtonId?: number;
  displayId?: number;
  buttonConfigurationId?: number;
  contacts: Contact[];
};
export type ButtonsWithContactsResponse = {
  contactListId: number;
  buttons: ButtonWithContactsResponse[];
};
export type UserWithApartmentsAndCredentialsAndAdminFlag = {
  firstName?: string;
  lastName: string;
  email?: string;
  role: UserRole;
  id: number;
  uuid: string;
  apartments: Apartment[];
  enabled: boolean;
  validFrom?: string;
  validTo?: string;
  credentials: RacCredentialsTypes[];
  admin: boolean;
};
export type ApartmentUserList = {
  resultsFiltered: number;
  resultsTotal: number;
  offset: number;
  limit: number;
  users: UserWithApartmentsAndCredentialsAndAdminFlag[];
};
export type SiteTypes = 'SINGLE_VILLA' | 'MDU' | 'OTHER' | 'UNKNOWN';
export type SiteRoles = {
  ADMIN?: number[];
  USER?: number[];
};
export type Service =
  | 'ACS'
  | 'MOBILE_VIDEO'
  | 'RAC'
  | 'LOCAL_CALLS'
  | 'GSM_CALLS'
  | 'NOTIFICATION'
  | 'REMOTE_CONFIGURATION'
  | 'INTERCOM_MONITORING'
  | 'REMOTE_API_ACCESS'
  | 'CREDENTIALS';
export type AcsService = {
  id: number;
  externalId?: string;
  type: Service;
  timezone?: string;
};
export type MobileVideoServiceProxy = {
  host?: string;
  port?: number;
  sipsPort?: number;
};
export type MobileVideoLicenceModelEnum = 'STANDARD' | 'ACTIVABLE' | 'EXPIRABLE';
export type MobileVideoLicenceModel = {
  type: MobileVideoLicenceModelEnum;
};
export type ActivableMobileVideoLicenceModelState = 'ENABLED' | 'DISABLED';
export type LicenceModelBillingType = 'PER_DEVICE' | 'PER_APARTMENT';
export type ActivableMobileVideoLicenceModel = MobileVideoLicenceModel & {
  id?: number;
  status?: ActivableMobileVideoLicenceModelState;
  licencesLimit?: number;
  unlicensedCount?: number;
  licensedCount?: number;
  maximalLicencesLimit?: number;
  paidCount?: number;
  billingType?: LicenceModelBillingType;
};
export type ExpirableMobileVideoLicenceModelState = 'ACTIVE' | 'EXPIRED';
export type ExpirableMobileVideoLicenceExtension = {
  id: number;
  period: string;
  dateCreated: string;
  validityFrom: string;
  validityTo: string;
};
export type ExpirableMobileVideoLicenceModel = MobileVideoLicenceModel & {
  id?: number;
  validityFrom?: string;
  validityTo?: string;
  billingType?: LicenceModelBillingType;
  billingTypeMultiplier?: number;
  status?: ExpirableMobileVideoLicenceModelState;
  licencesLimit?: number;
  unlicensedCount?: number;
  licensedCount?: number;
  maximalLicencesLimit?: number;
  extensions?: ExpirableMobileVideoLicenceExtension[];
  paidCount?: number;
};
export type StandardMobileVideoLicenceState =
  | 'INITIAL'
  | 'TRIAL_ACTIVE'
  | 'TRIAL_EXPIRED'
  | 'STANDARD'
  | 'STANDARD_EXPIRED'
  | 'STANDARD_RENEWAL'
  | 'TEMPORARY';
export type MobileVideoProduct =
  | 'MOBILE_VIDEO_MONTH'
  | 'MOBILE_VIDEO_YEAR'
  | 'APARTMENT_MOBILE_VIDEO_MONTH'
  | 'APARTMENT_MOBILE_VIDEO_YEAR'
  | 'MOBILE_VIDEO_UNLIMITED';
export type MobileVideoProductPurchaseCalculation = {
  chargeVat?: boolean;
  licences?: number;
  licencesPool?: number;
  multiplier?: number;
  percentage?: number;
  price?: number;
  priceVat?: number;
  purchase?: number;
  unitFullPrice?: number;
  unitFullPriceVat?: number;
};
export type NextPayment = {
  calculation?: MobileVideoProductPurchaseCalculation;
  date?: string;
};
export type StandardMobileVideoLicenceModel = MobileVideoLicenceModel & {
  additional?: number;
  billingType?: LicenceModelBillingType;
  billingTypeMultiplier?: number;
  combinedMultiplier?: number;
  consumptionMultiplier?: number;
  extensions?: ExpirableMobileVideoLicenceExtension[];
  id?: number;
  licencesLimit?: number;
  licencesPool?: number;
  licensedCount?: number;
  maximalLicencesLimit?: number;
  nextPayment?: NextPayment;
  paidCount?: number;
  poolExpiration?: string;
  product?: MobileVideoProduct;
  requirePaymentCount?: number;
  status?: StandardMobileVideoLicenceState;
  unlicensedCount?: number;
  validityFrom?: string;
  validityTo?: string;
};
export type MobileVideoService = {
  deviceLimit: number;
  externalId?: string;
  id: number;
  licenceModel: ActivableMobileVideoLicenceModel | ExpirableMobileVideoLicenceModel | StandardMobileVideoLicenceModel;
  phonePrefix: string;
  proxy: MobileVideoServiceProxy;
  ringingGroupSizeLimit: number;
  sipsEnabled?: boolean;
  srtpEnabled?: boolean;
  type: Service;
};
export type LocalCallsService = {
  externalId?: string;
  id: number;
  type: Service;
};
export type GsmCallsService = {
  externalId?: string;
  id: number;
  type: Service;
};
export type NotificationService = {
  externalId?: string;
  id: number;
  type: Service;
};
export type RemoteConfigurationService = {
  externalId?: string;
  id: number;
  type: Service;
};
export type IntercomMonitoringService = {
  externalId?: string;
  id: number;
  type: Service;
};
export type RemoteApiAccessService = {
  externalId?: string;
  id: number;
  type: Service;
};
export type CredentialsService = {
  externalId?: string;
  id: number;
  type: Service;
};
export type RacService = {
  externalId?: string;
  id: number;
  type: Service;
};
export type AccessControlService = {
  bluetoothEnabled?: boolean | null;
  guestPinLength: number;
  guestShowQr: boolean;
};
export type SiteServicesWithAccessControlService = {
  ACCESS_CONTROL: AccessControlService;
  ACS?: AcsService;
  CREDENTIALS?: CredentialsService;
  GSM_CALLS?: GsmCallsService;
  INTERCOM_MONITORING?: IntercomMonitoringService;
  LOCAL_CALLS?: LocalCallsService;
  MOBILE_VIDEO?: MobileVideoService;
  NOTIFICATION?: NotificationService;
  RAC?: RacService;
  REMOTE_API_ACCESS?: RemoteApiAccessService;
  REMOTE_CONFIGURATION?: RemoteConfigurationService;
};
export type SiteWithGuestPinSettingsResponse = {
  apartmentDeviceLimit: number;
  dateCreated: string;
  externalId?: string;
  id: number;
  lastEventDate?: string;
  locale: string;
  my2nId: string;
  name: string;
  roles: SiteRoles;
  services: SiteServicesWithAccessControlService;
  type: SiteTypes;
};
export type ApartmentUserWithCredentialsAndMvStatus = {
  credentials: RacCredentialsTypes[];
  email?: string;
  enabled: boolean;
  firstName?: string;
  id: number;
  lastName: string;
  locale: string;
  mobileVideoActivated: boolean;
  role: ApartmentUserRole;
  uuid: string;
  validFrom?: string;
  validTo?: string;
};
export type SiteInfo = {
  id: number;
  name: string;
};
export type CompanyInfo = {
  id: number;
};
export type ApartmentInfoWithUsersList = {
  apartmentMobileVideoState: ApartmentMobileVideoState;
  company: CompanyInfo;
  contactLabels: string[];
  devicesCount: number;
  id: number;
  name?: string;
  number: string;
  paidDevicesCount: number;
  paidDevicesLimit: number;
  paidServicesActive?: boolean;
  site: SiteInfo;
  users: ApartmentUserWithCredentialsAndMvStatus[];
};
export type ApartmentDetailResponse = {
  accessGroupNames?: string[];
  apartmentMobileVideoState: ApartmentMobileVideoState;
  contactLabels: string[];
  devicesCount: number;
  id: number;
  name?: string;
  number: string;
  paidDevicesCount: number;
  paidDevicesLimit: number;
  paidServicesActive?: boolean;
  siteName: string;
  usersCount: number;
};
export const {
  useApartmentAdminDeleteApartmentUserByIdMutation,
  useBulkCreateLoginLessUserMutation,
  useCheckUserExistenceInSiteOfApartmentMutation,
  useCountContactButtonsForDeviceQuery,
  useCreateGuestAndAssignPinInApartmentMutation,
  useCreateGuestAndAssignPinInSiteMutation,
  useCreateLoginLessAndAssignPinInApartmentMutation,
  useCreateSiteUserMutation,
  useCreateUserDeviceMutation,
  useCreateUserInApartmentOfApartmentAdminMutation,
  useDeleteApartmentUserByIdMutation,
  useDeleteSiteUserByIdMutation,
  useEditGuestInApartmentMutation,
  useEditGuestInSiteMutation,
  useElevatorIntegrationFloorMappingQuery,
  useGetApartmentDetailForAaQuery,
  useGetApartmentUserForAaByIdQuery,
  useGetCreditCostUntilEndOfMonthQuery,
  useGetSiteQuery,
  useGetSiteUserByIdQuery,
  useListApartmentUsersByApartmentAdminGroupedByApartmentQuery,
  useListApartmentUsersQuery,
  useListContactButtonsForDeviceQuery,
  useListGroupUsersQuery,
  useListSiteUsersQuery,
  useListSiteUsersWithGroupsQuery,
  useModifySiteUserByIdMutation,
  useUpdateApartmentLabelMutation,
  useUpdateUserBasicInfoInApartmentMutation,
} = injectedRtkApi;
