import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { sum } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { APARTMENT_STATUS, PATHS } from '../../config/consts';
import { useUrlParams } from '../../helpers/urlParams';
import ButtonLink from '../ButtonLink';
import DashboardCardHeader from '../DashboardCardHeader';
import Apartment from './Apartment';
import messages from './messages';

const styles = {
  content: {
    pb: 3,
    pt: 0,
    px: 3,
  },
  count: {
    color: 'text.primary',
    fontSize: '68px',
    fontWeight: 300,
    lineHeight: '50px',
    pt: { sm: 2, xs: 0 },
  },
  leftBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 2,
    pr: { sm: 1, xs: 0 },
  },
  root: {
    height: '100%',
  },
};

const ApartmentsOverviewCard = ({ apartments }) => {
  const { companyId, siteId } = useUrlParams();
  const count = sum(Object.values(apartments));
  const getCount = (type) => apartments[type] || 0;

  return (
    <Card sx={styles.root}>
      <DashboardCardHeader
        action={
          <ButtonLink
            color="primary"
            to={PATHS.APARTMENTS_OVERVIEW.replace(':companyId', companyId).replace(':siteId', siteId)}
            variant="text"
          >
            <FormattedMessage {...messages.apartmentsOverviewCardSeeAllApartments} />
          </ButtonLink>
        }
        title={<FormattedMessage {...messages.apartmentsOverviewCardTitle} />}
      />
      <Grid container sx={styles.content}>
        <Grid container item sm={5} sx={styles.leftBox} xs={12}>
          <Grid item sm={12} xs={6}>
            <Typography sx={styles.count}>{count}</Typography>
            <Typography sx={{ mt: 1 }} variant="body2">
              <FormattedMessage {...messages.apartmentsOverviewCardAddedApartments} values={{ count }} />
            </Typography>
          </Grid>
        </Grid>
        <Grid item sm={7} sx={{ mt: { sm: 2, xs: 1 } }} xs={12}>
          <Apartment count={getCount(APARTMENT_STATUS.ACTIVE_SUBSCRIPTIONS)}>
            <FormattedMessage
              {...apartmentStatusMap[APARTMENT_STATUS.ACTIVE_SUBSCRIPTIONS]}
              values={{
                bold: (number) => <strong>{number}</strong>,
                count: getCount(APARTMENT_STATUS.ACTIVE_SUBSCRIPTIONS),
              }}
            />
          </Apartment>
          <Apartment count={getCount(APARTMENT_STATUS.ACTIVE_FOR_FREE)} tooltip>
            <FormattedMessage
              {...apartmentStatusMap[APARTMENT_STATUS.ACTIVE_FOR_FREE]}
              values={{
                bold: (number) => <strong>{number}</strong>,
                count: getCount(APARTMENT_STATUS.ACTIVE_FOR_FREE),
              }}
            />
          </Apartment>
          <Apartment count={0}>
            <FormattedMessage
              {...apartmentStatusMap[APARTMENT_STATUS.NO_SERVICE]}
              values={{
                bold: (number) => <strong>{number}</strong>,
                count: getCount(APARTMENT_STATUS.NO_SERVICE),
              }}
            />
          </Apartment>
          {getCount(APARTMENT_STATUS.UNLICENSED) > 0 && (
            <Apartment count={getCount(APARTMENT_STATUS.UNLICENSED)} isError isLastChild>
              <FormattedMessage
                {...apartmentStatusMap[APARTMENT_STATUS.UNLICENSED]}
                values={{
                  bold: (number) => <strong>{number}</strong>,
                  count: getCount(APARTMENT_STATUS.UNLICENSED),
                }}
              />
            </Apartment>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

ApartmentsOverviewCard.propTypes = {
  apartments: PropTypes.shape({
    [APARTMENT_STATUS.ACTIVE_FOR_FREE]: PropTypes.number,
    [APARTMENT_STATUS.ACTIVE_SUBSCRIPTIONS]: PropTypes.number,
    [APARTMENT_STATUS.NO_SERVICE]: PropTypes.number,
    [APARTMENT_STATUS.UNLICENSED]: PropTypes.number,
    [APARTMENT_STATUS.OTHER]: PropTypes.number,
  }).isRequired,
};

export default ApartmentsOverviewCard;

const apartmentStatusMap = {
  [APARTMENT_STATUS.ACTIVE_SUBSCRIPTIONS]: messages.apartmentsOverviewCardActiveSubscriptions,
  [APARTMENT_STATUS.ACTIVE_FOR_FREE]: messages.apartmentsOverviewCardActiveForFree,
  [APARTMENT_STATUS.NO_SERVICE]: messages.apartmentsOverviewCardNoService,
  [APARTMENT_STATUS.UNLICENSED]: messages.apartmentsOverviewCardUnlicensed,
};
