import { defineMessages } from 'react-intl';

export default defineMessages({
  accessUnitSetupInfo: {
    defaultMessage:
      'You can display access log in My2N. It includes information about time,' +
      ' user, device, access method, and success of the access. This can be disabled anytime.',
    description: 'Access unit setup - info',
    id: 'accessUnitSetup.info',
  },
  configurationModeSetupConfigurationModeAutomatic: {
    defaultMessage: 'Automatic',
    description: 'Configuration mode - automatic',
    id: 'configurationModeSetup.configurationMode.automatic',
  },
  configurationModeSetupConfigurationModeHelperText: {
    defaultMessage: 'Automatic mode simplifies system setup',
    description: 'Configuration mode field helper text',
    id: 'configurationModeSetup.configurationMode.helperText',
  },
  configurationModeSetupConfigurationModeLabel: {
    defaultMessage: 'Configuration mode',
    description: 'Configuration mode field label',
    id: 'configurationModeSetup.configurationMode.label',
  },
  configurationModeSetupConfigurationModeManual: {
    defaultMessage: 'Manual',
    description: 'Configuration mode - manual',
    id: 'configurationModeSetup.configurationMode.manual',
  },
  configurationModeSetupConnectionModeCloud: {
    defaultMessage: 'Cloud',
    description: 'Configuration mode - Cloud',
    id: 'configurationModeSetup.connectionMode.cloud',
  },
  configurationModeSetupConnectionModeHelperText: {
    defaultMessage: 'With cloud, you needn’t set up local network',
    description: 'Connection mode field helper text',
    id: 'configurationModeSetup.connectionMode.helperText',
  },
  configurationModeSetupConnectionModeLabel: {
    defaultMessage: 'Connection mode',
    description: 'Connection mode field label',
    id: 'configurationModeSetup.connectionMode.label',
  },
  configurationModeSetupConnectionModeLocal: {
    defaultMessage: 'Local',
    description: 'Configuration mode - Local',
    id: 'configurationModeSetup.connectionMode.local',
  },
  configurationModeSetupDescription: {
    defaultMessage:
      'You can choose how your device is configured and connected. We recommend' +
      ' automatic configuration and cloud connection.',
    description: 'Configuration mode description',
    id: 'configurationModeSetup.description',
  },
  configurationModeSetupMobileVideoDescription: {
    defaultMessage: '<strong>Mobile video service</strong> (Requires paid subscription' + ' or a voucher)',
    description: 'Mobile video mode description',
    id: 'configurationModeSetup.mobileVideo.description',
  },
  configurationModeSetupMobileVideoInfo: {
    defaultMessage: 'Calls between intercom, mobile phones and answering units over cloud.',
    description: 'Mobile video mode info',
    id: 'configurationModeSetup.mobileVideo.info',
  },
  configurationModeSetupMobileVideoLabel: {
    defaultMessage: 'Turn on the service Mobile Video',
    description: 'Mobile video mode field label',
    id: 'configurationModeSetup.mobileVideo.label',
  },
  configurationModeSetupServices: {
    defaultMessage: 'Choose the services you want to use for your {deviceName}',
    description: 'Choose the services text',
    id: 'configurationModeSetup.services',
  },
  formActionBackBtn: {
    defaultMessage: 'Back',
    description: 'Form action - back button',
    id: 'x2nDevice.second.formAction.backBtn',
  },
  formActionCancelBtn: {
    defaultMessage: 'Cancel',
    description: 'Form action - cancel button',
    id: 'x2nDevice.second.formAction.cancelBtn',
  },
  formActionNextBtn: {
    defaultMessage: 'Next',
    description: 'Form action - next button',
    id: 'x2nDevice.second.formAction.nextBtn',
  },
  idtIdentifierDescriptionIdt1: {
    defaultMessage:
      'Open the 2N<sup>®</sup> IP Mobile application on your 2N<sup>®</sup> Indoor Touch.' +
      ' Go to the settings section and click on Settings.' +
      ' Make a note of the device name code and enter it into this field.',
    description: 'IDT 1.0 identifier - description',
    id: 'idtIdentifier.descriptionIdt1',
  },
  idtIdentifierDescriptionIdt2: {
    defaultMessage:
      'First, set the Indoor Touch ID on the device.{br}{br} Open the Intercoms application' +
      ' on your Indoor Touch 2. Go to Settings. Click on General Settings.' +
      ' Name your device and enter the name (ID) in the field below.',
    description: 'IDT 2.0. identifier - description',
    id: 'idtIdentifier.descriptionIdt2',
  },
  idtIdentifierExampleIdt1: {
    defaultMessage: 'e. g. indoortouch-1002285',
    description: 'IDT identifier - example idt 1',
    id: 'idtIdentifier.example.idt1',
  },
  idtIdentifierExampleIdt2: {
    defaultMessage: 'Device ID = the name set on the device',
    description: 'IDT identifier - example idt 2',
    id: 'idtIdentifier.example.idt2',
  },
  idtIdentifierFaq: {
    defaultMessage: 'For a step-by-step guide, see the <url>2N FAQ</url>',
    description: 'IDT identifier - faq',
    id: 'idtIdentifier.faq',
  },
  idtIdentifierLabelIdt1: {
    defaultMessage: '2N Indoor Touch identifier',
    description: 'IDT identifier - label idt 1',
    id: 'idtIdentifier.label.idt1',
  },
  idtIdentifierLabelIdt2: {
    defaultMessage: 'Device ID',
    description: 'IDT identifier - label idt 2',
    id: 'idtIdentifier.label.idt2',
  },
});
