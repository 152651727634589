import AddIcon from '@mui/icons-material/Add';
import DialpadIcon from '@mui/icons-material/Dialpad';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useGetUserCredentialsValidityQuery } from '../../apiRtk/accessControlApi';
import { useUrlParams } from '../../helpers/urlParams';
import { useModal } from '../../hooks/useModal';
import { getApartmentDevices } from '../../modules/apartments/selectors';
import { VisitorModalContext } from '../../modules/commonTypes';
import { getSiteData } from '../../modules/sites/selectors';
import { IUser } from '../../modules/user/store';
import { isUserGuest } from '../../modules/user/utils';
import VisitorModal from '../../pages/apartmentAdmin/ApartmentAdminUsersOverviewPage/components/VisitorModal/VisitorModal';
import RevokeUserPinDialog from '../RevokeUserPinModal';
import UserPinModalNewComponent from '../UserPinModalNew';
import CredentialAlert from './CredentialAlert';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import messages from './messages';
import PanelPIN from './PanelPIN';
import SectionTitle from './SectionTitle';

interface ISectionPin {
  user: IUser;
  userPinData?: {
    active: boolean;
    id: string;
    pin: string;
  };
}

export interface EnrichedIUser extends IUser {
  validFrom?: string;
  validTo?: string;
}

function SectionPin({ user, userPinData }: ISectionPin) {
  const { formatMessage, isCompanyAdmin, isSiteAdmin } = useGetGlobalHooks();
  const isEmpty = !userPinData?.pin;
  const { companyId, siteId, userId } = useUrlParams();
  const siteData = useSelector(getSiteData);
  const apartmentDevices = useSelector(getApartmentDevices).data || [];

  const { Modal: RevokeUserPinModal, onOpen: onOpenRevokeUserPinModal } = useModal({
    Modal: RevokeUserPinDialog,
  });
  const { Modal: UserPinModalNew, onOpen: onOpenUserPinModal } = useModal({
    Modal: UserPinModalNewComponent,
  });
  const { Modal: VisitorUModal, onOpen: onOpenVisitorUModal } = useModal({
    Modal: VisitorModal,
  });

  const [displayInfoBox, setDisplayInfoBox] = useState(isEmpty);

  const toggleDisplayInfoBox = () => {
    setDisplayInfoBox((prevState) => !prevState);
  };

  const [enrichedUser, setEnrichedUser] = useState(user as EnrichedIUser);

  const { data: userCredentialsValidityData } = useGetUserCredentialsValidityQuery({
    companyId: companyId!.toString(),
    siteId: siteId!.toString(),
    userId: userId!.toString(),
  });

  useEffect(() => {
    setEnrichedUser({
      ...user,
      validFrom: userCredentialsValidityData?.validFrom,
      validTo: userCredentialsValidityData?.validTo,
    });
  }, [userCredentialsValidityData]);

  return (
    <>
      <SectionTitle
        isEmpty={isEmpty}
        onInfoClick={toggleDisplayInfoBox}
        startIcon={<DialpadIcon color={isEmpty ? 'inherit' : 'primary'} />}
        title={formatMessage(messages.userAccessFormPinTitle)}
      />
      {isEmpty ? (
        <Button
          color="primary"
          onClick={isUserGuest(user.role) ? onOpenVisitorUModal : onOpenUserPinModal}
          startIcon={<AddIcon />}
          sx={{ mb: 2 }}
          variant="outlined"
        >
          <FormattedMessage {...messages.userAccessFormPinGenerateNewPin} />
        </Button>
      ) : (
        <PanelPIN concealable pin={userPinData?.pin} />
      )}
      {displayInfoBox && (
        <CredentialAlert
          infoText={formatMessage(messages.userAccessFormPinInfoDescription, {
            br: <br />,
          })}
        />
      )}
      {!isEmpty && (
        <Grid sx={{ mt: 4 }}>
          <Button color="error" disabled={!(isCompanyAdmin || isSiteAdmin)} onClick={onOpenRevokeUserPinModal}>
            <FormattedMessage {...messages.revokeUserPinButtonDeletePin} />
          </Button>
        </Grid>
      )}
      <UserPinModalNew context="SITE_USER_DETAIL" user={user} />
      <RevokeUserPinModal user={user} />
      <VisitorUModal
        msgContext={VisitorModalContext.AA_MODAL_NEW_GUEST_PIN}
        apartmentInfo={{ companyId: companyId!, siteId: siteId! }}
        apartmentDevices={user?.apartments.length ? apartmentDevices : []}
        siteName={siteData.name}
        user={enrichedUser}
        isRegenerate
      />
    </>
  );
}

export default SectionPin;
