import { be4feApi } from './be4feApi';

export type Be4feTagTypes = 'apartment-admin' | 'elevator-integration' | 'access-control' | 'site-settings';

export const enhancedBe4FEApi = be4feApi.enhanceEndpoints<Be4feTagTypes>({
  addTagTypes: ['apartment-admin'],
  endpoints: {
    editGuestInApartment: {
      invalidatesTags: [{ id: 'user-detail', type: 'apartment-admin' }],
    },
    editGuestInSite: {
      invalidatesTags: [{ id: 'user-credentials-validity', type: 'access-control' }],
    },
    elevatorIntegrationFloorMapping: {
      providesTags: [{ id: 'floor-mapping', type: 'elevator-integration' }],
    },
    getApartmentUserForAaById: {
      providesTags: [{ id: 'user-detail', type: 'apartment-admin' }],
    },
    getSite: {
      providesTags: [{ id: 'site', type: 'site-settings' }],
    },
    updateUserBasicInfoInApartment: {
      invalidatesTags: [{ id: 'user-detail', type: 'apartment-admin' }],
    },
  },
});
