import { defineMessages } from 'react-intl';

export default defineMessages({
  deviceForFreeTooltipApartmentsFree: {
    defaultMessage: 'Mobile Video is free for all apartments using only 2N devices' + ' (no mobile phones or tablets).',
    description: '',
    id: 'deviceForFreeTooltip.apartmentsFree',
  },
  deviceForFreeTooltipDevicesFree: {
    defaultMessage: 'Mobile Video runs for free on all 2N devices',
    description: 'Device for free tooltip - devices free',
    id: 'deviceForFreeTooltip.devicesFree',
  },
  deviceForFreeTooltipFree: {
    defaultMessage: 'Free',
    description: 'Device for free tooltip - free',
    id: 'deviceForFreeTooltip.free',
  },
});
