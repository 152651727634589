import { FormControl, InputLabel } from '@mui/material';
import MuiSelect, { SelectProps } from '@mui/material/Select';
import React from 'react';
import { Control, Controller, FieldPath, FieldValues, useFormContext } from 'react-hook-form';

interface ISelectWithControl<T extends FieldValues> extends SelectProps {
  control: Control<T>;
  name: FieldPath<T>;
}

export interface ISelect<T extends FieldValues> extends SelectProps {
  name: FieldPath<T>;
}

export function Select<T extends FieldValues>({ name, ...other }: ISelect<T>): React.JSX.Element;
/** @deprecated primary use component without control (control is acquired from context) */
export function Select<T extends FieldValues>({ control, name, ...other }: ISelectWithControl<T>): React.JSX.Element;

export function Select<T extends FieldValues>({ control, id, label, name, required, ...other }: ISelectWithControl<T>) {
  const { control: contextControl } = useFormContext<T>();
  return (
    <Controller
      control={control ?? contextControl}
      name={name}
      render={({ field, fieldState }) => (
        <FormControl sx={{ mb: 1, mt: 2 }}>
          <InputLabel
            error={!!fieldState?.error}
            disableAnimation
            required={required}
            shrink
            htmlFor={id}
            variant="outlined"
          >
            {label}
          </InputLabel>
          <MuiSelect {...other} {...field} notched label={label} />
        </FormControl>
      )}
    />
  );
}

export default Select;
