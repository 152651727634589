import { APARTMENT_STATUS } from '../../config/consts';
import { IApartment, IApartmentBase } from '../apartments/store';
import { FERoles, TUuid } from '../commonTypes';
import { IUser } from '../user/store';

export enum ApartmentUserRole {
  APARTMENT_USER = 'APARTMENT_USER',
  APARTMENT_ADMIN_INHERITED = 'APARTMENT_ADMIN_INHERITED',
  APARTMENT_ADMIN = 'APARTMENT_ADMIN',
  APARTMENT_LOGIN_LESS = 'APARTMENT_LOGIN_LESS',
  APARTMENT_GUEST = 'APARTMENT_GUEST',
}

export type ApartmentUser = Omit<IUser, 'apartments' | 'groups' | 'role' | 'email' | 'firstName'> & {
  enabled: boolean;
  email?: string;
  role: ApartmentUserRole;
  firstName?: string;
  validFrom?: string;
  validTo?: string;
};

export interface EnrichedApartmentUser extends ApartmentUser {
  apartments: {
    id: number;
  }[];
}

export type ApartmentUserWithMVStatus = { mobileVideoActivated: boolean } & ApartmentUser;

export interface IAggregatedApartmentUsers extends Omit<IApartment, 'floor' | 'label' | 'name'> {
  devicesCount: number;
  paidDevicesCount: number;
  paidDevicesLimit: number;
  apartmentMobileVideoState: keyof typeof APARTMENT_STATUS;
  contactLabels: string[];
  users: ApartmentUserWithMVStatus[];
  site: { id: number; name: string };
  company: { id: number };
  name?: string;
}

export interface ICreateApartmentUser {
  email: string;
  firstName: string;
  lastName: string;
  mobileVideoActivated: boolean;
  role?: FERoles;
}

export type AccessGroupName = string;

export interface IAccessGroup {
  id: TUuid;
  name: AccessGroupName;
  description: string;
  siteId: TUuid;
}

export interface IApartmentWithAccessGroups extends IApartmentBase {
  siteId: TUuid;
  coreId: TUuid;
  devicesCount: number;
  groups: IAccessGroup[];
}
