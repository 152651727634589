import DeactivateIcon from '@mui/icons-material/Cancel';
import ActivateIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useGetLoadedParams } from '../../hooks/paramsHooks';
import { ApplicationContext, IModalBase } from '../../modules/commonTypes';
import { enableUserMy2NAppAccess, TUserActionContext } from '../../modules/user/actionsTS';
import { IUser } from '../../modules/user/store';
import { ActionModal, ActionModalError } from '../_DesignSystemWrappers/Modal';
import Alert from '../Alert';
import UserInfo from '../UserInfo';
import messages from './mesages';

export interface TUserActionContextModal extends IModalBase {
  user: IUser;
  context: TUserActionContext;
  onActivate: (data: boolean) => void;
}

function My2NAppAccessModal({ context, onActivate, onClose, open, user }: TUserActionContextModal) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { companyId, siteId } = useGetLoadedParams(ApplicationContext.Site);

  function handleSetMy2NAppAccess(value: boolean) {
    dispatch(enableUserMy2NAppAccess(companyId, siteId, user.id, value, context));
    onActivate(value);
    onClose();
  }

  return user?.credentials?.includes('BT') ? (
    <ActionModalError
      icon={<DeactivateIcon />}
      isPrimaryButtonDisabled={false}
      muiDialogProps={{ fullWidth: true, maxWidth: 'xs' }}
      onClose={onClose}
      open={open}
      primaryButtonAction={() => handleSetMy2NAppAccess(false)}
      primaryButtonText={formatMessage(messages.disableMy2NAccessModalConfirmButton)}
      secondaryButtonText={formatMessage(messages.enableMy2NAccessModalCancelButton)}
      title={formatMessage(messages.disableMy2NAccessModalTitle)}
    >
      <Typography component="span" variant="body2">
        <FormattedMessage {...messages.disableMy2NAccessModalUserText} values={{ p: (chunks) => <p>{chunks}</p> }} />
      </Typography>
      <UserInfoComponent user={user} />
      <Typography component="span" variant="body2">
        <FormattedMessage {...messages.disableMy2NAccessModalInfoText} values={{ p: (chunks) => <p>{chunks}</p> }} />
      </Typography>
    </ActionModalError>
  ) : (
    <ActionModal
      icon={<ActivateIcon />}
      isPrimaryButtonDisabled={false}
      muiDialogProps={{ fullWidth: true, maxWidth: 'xs' }}
      onClose={onClose}
      open={open}
      primaryButtonAction={() => handleSetMy2NAppAccess(true)}
      primaryButtonText={formatMessage(messages.enableMy2NAccessModalConfirmButton)}
      secondaryButtonText={formatMessage(messages.enableMy2NAccessModalCancelButton)}
      title={formatMessage(messages.enableMy2NAccessModalTitle)}
    >
      <Typography component="span" variant="body2">
        <FormattedMessage {...messages.enableMy2NAccessModalUserText} values={{ p: (chunks) => <p>{chunks}</p> }} />
      </Typography>
      <UserInfoComponent user={user} />
      <Alert severity="error" variant="standard">
        <FormattedMessage
          {...messages.enableMy2NAccessModalAlert}
          values={{
            br: <br />,
          }}
        />
      </Alert>
      <Typography component="span" variant="body2">
        <FormattedMessage {...messages.enableMy2NAccessModalInfoText} values={{ p: (chunks) => <p>{chunks}</p> }} />
      </Typography>
    </ActionModal>
  );
}

function UserInfoComponent({ user }: { user: IUser }) {
  return (
    <Box pb={1} pt={2}>
      <UserInfo user={user} />
    </Box>
  );
}

export default My2NAppAccessModal;
