import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { conditionalArray } from '../../helpers/array';
import { useModal } from '../../hooks/useModal';
import { selectors } from '../../modules/user';
import { AccessType, IUser } from '../../modules/user/store';
import { My2NAppAccessIcon } from '../_Icons';
import My2NAppAccessModalComponent from '../My2NAppAccessModal';
import CredentialSection from './CredentialSection';
import messages from './messages';
import PanelMy2NAppAccess from './PanelMy2NAppAccess';

function SectionMy2NAppAccess({ user }: { user: IUser }) {
  const { formatMessage } = useIntl();
  const userMy2NAppAccessStatus = useSelector(selectors.getUserMy2NAppAccessEnabledStatus);
  const [isActivated, setIsActivated] = useState(false);
  const { Modal: My2NAppAccessModal, onOpen: onOpenMy2NAppAccessModal } = useModal({
    Modal: My2NAppAccessModalComponent,
  });

  return (
    <>
      <CredentialSection
        addCredentialButtonAction={onOpenMy2NAppAccessModal}
        addCredentialButtonnLabel={formatMessage(messages.sectionMy2NAppAccessAddButtonLabel)}
        deleteButtonAction={onOpenMy2NAppAccessModal}
        deleteButtonLabel={formatMessage(messages.sectionMy2NAppAccessDeleteButtonLabel)}
        infoText={formatMessage(messages.sectionMy2NAppAccessInfo, {
          p: (chunks: string): any => <p>{chunks}</p>,
        })}
        isActive={userMy2NAppAccessStatus}
        panel={<PanelMy2NAppAccess isActivated={isActivated} />}
        startIcon={My2NAppAccessIcon}
        title={formatMessage(messages.sectionMy2NAppAccessTitle)}
      />
      <My2NAppAccessModal
        context="SITE_USER_DETAIL"
        onActivate={setIsActivated}
        user={{
          ...user,
          credentials: conditionalArray([userMy2NAppAccessStatus && 'BT']) as AccessType[],
        }}
      />
    </>
  );
}

export default SectionMy2NAppAccess;
