import { defineMessages } from 'react-intl';

export default defineMessages({
  apartmentFreeTooltipHeader: {
    defaultMessage: 'Free',
    description: 'Apartment Free Tooltip - header',
    id: 'apartmentFreeTooltip.header',
  },
  apartmentFreeTooltipText: {
    defaultMessage: 'Mobile Video is free for all apartments using only 2N devices (no mobile phones or tablets).',
    description: 'Apartment Free Tooltip - text',
    id: 'apartmentFreeTooltip.text',
  },
});
