import MuiAutocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';

function Autocomplete({ control, inputSettings, name, ...other }) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <MuiAutocomplete
          {...other}
          {...field}
          onChange={(e, value) => field.onChange(value || '')}
          onInputChange={(_, data) => field.onChange(data || '')}
          renderInput={(params) => (
            <TextField
              {...inputSettings}
              {...params}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message || inputSettings.helperText || ' '}
              InputLabelProps={{
                ...params.InputLabelProps,
                ...inputSettings?.InputLabelProps,
              }}
              inputRef={field.ref}
            />
          )}
        />
      )}
    />
  );
}

Autocomplete.propTypes = {
  control: PropTypes.shape({}).isRequired,
  inputSettings: PropTypes.shape({
    helperText: PropTypes.string,
    InputLabelProps: PropTypes.shape({}),
  }),
  name: PropTypes.string.isRequired,
};

Autocomplete.defaultProps = {
  inputSettings: {},
};

export default Autocomplete;
