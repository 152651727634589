import { Box, Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';
import React, { useState } from 'react';
import NonClosingDialog from '../../../../components/_DesignSystemWrappers/Modal/NonClosingModal';
import InstallAppModalContent from '../../../../components/InstallAppModalContent';
import { useModal } from '../../../../hooks/useModal';
import { IModalBase, TId } from '../../../../modules/commonTypes';
import { IDevice } from '../../../../modules/devices/store/deviceStore';
import { hasApartmentExceededMVLimit } from '../../utils/currentApartmentDetailData';
import { styles } from '../components/DetailItem/styles';
import ActivateMVDialog from './ActivateMVModal';
import DeactivateMVDialog from './DeactivateMVModal';
import messages from './messages';
import MVMultipleDevices from './MVMultipleDevices';
import MVSingleDevice from './MVSingleDevice';
import { useApartmentAdminUserMVService } from './useApartmentAdminUserMVService';

export interface IApartmentAdminUserMVService {
  apartmentId: TId;
  companyId: TId;
  siteId: TId;
  userId: TId;
  paidDevicesCount?: number;
  paidDevicesLimit?: number;
  isUnlicensed: boolean;
}

function ApartmentAdminUserMVService({
  apartmentId,
  companyId,
  isUnlicensed,
  paidDevicesCount,
  paidDevicesLimit,
  siteId,
  userId,
}: IApartmentAdminUserMVService) {
  const hasExceededMVLimit = hasApartmentExceededMVLimit(paidDevicesCount, paidDevicesLimit);
  const { devices, formatMessage, isFetching } = useApartmentAdminUserMVService(apartmentId, companyId, siteId, userId);

  const [currentDevice, setCurrentDevice] = useState<IDevice | undefined>(undefined);

  const { Modal: InfoModal, onOpen: onOpenInfoModal } = useModal({
    Modal: ({ onClose }: IModalBase) => (
      <NonClosingDialog
        title={formatMessage(messages.AAModalInstallAppTitle)}
        closeOnlyByButton={false}
        hideCloseButton={false}
        primaryButtonText={formatMessage(messages.AAModalInstallAppClose)}
        secondaryButtonText={undefined}
        onClose={onClose}
      >
        <InstallAppModalContent context="installApp" />
      </NonClosingDialog>
    ),
  });

  const { Modal: ActivateMVModal, onOpen: onOpenActivateMVModal } = useModal({
    Modal: ActivateMVDialog,
  });

  const { Modal: DeactivateMVModal, onOpen: onOpenDeactivateMVModal } = useModal({
    Modal: DeactivateMVDialog,
  });

  if (isFetching) {
    return (
      <Box sx={styles.skeletonWrapper}>
        <Skeleton width={'75%'} />
      </Box>
    );
  }

  return (
    <Stack spacing={2}>
      {devices && devices?.length > 1 ? (
        devices?.map((device) => (
          <MVMultipleDevices
            device={device}
            key={device.id}
            onOpenActivateMVModal={onOpenActivateMVModal}
            onOpenDeactivateMVModal={onOpenDeactivateMVModal}
            onOpenInfoModal={onOpenInfoModal}
            setCurrentDevice={setCurrentDevice}
            hasExceededMVLimit={hasExceededMVLimit}
            isUnlicensed={isUnlicensed}
          />
        ))
      ) : (
        <MVSingleDevice
          device={devices?.length === 0 ? undefined : devices?.[0]}
          onOpenActivateMVModal={onOpenActivateMVModal}
          onOpenDeactivateMVModal={onOpenDeactivateMVModal}
          onOpenInfoModal={onOpenInfoModal}
          setCurrentDevice={setCurrentDevice}
          hasExceededMVLimit={hasExceededMVLimit}
          isUnlicensed={isUnlicensed}
        />
      )}
      <InfoModal />
      <ActivateMVModal
        apartmentId={apartmentId}
        companyId={companyId}
        device={currentDevice}
        siteId={siteId}
        userId={userId}
      />
      {currentDevice && (
        <DeactivateMVModal apartmentId={apartmentId} companyId={companyId} device={currentDevice} siteId={siteId} />
      )}
    </Stack>
  );
}

export default ApartmentAdminUserMVService;
