import { defineMessages } from 'react-intl';

export default defineMessages({
  formActionAddNewDeviceBtn: {
    defaultMessage: 'Add new device',
    description: 'Form action - add new device button',
    id: 'finishedStep.formAction.addNewDeviceBtn',
  },
  formActionBackBtn: {
    defaultMessage: 'Back',
    description: 'Form action - back button',
    id: 'finishedStep.formAction.backBtn',
  },
  formActionCloseBtn: {
    defaultMessage: 'Close',
    description: 'Form action - close button',
    id: 'finishedStep.formAction.closeBtn',
  },
  noVerifiedMessageDescription: {
    defaultMessage:
      'Double-check if the serial number and Security Code are up-to-date and' +
      ' match the device you are verifying. If they are correct, our {link} is ready to' +
      ' help you.',
    description: 'No verified Message - description',
    id: 'noVerifiedMessage.description',
  },
  noVerifiedMessageMessage: {
    defaultMessage: 'The device could not be added',
    description: 'No verified Message - message',
    id: 'noVerifiedMessage.message',
  },
  noVerifiedMessageTechnicalSupportTeam: {
    defaultMessage: 'Technical Support team',
    description: 'No verified Message - technical support team',
    id: 'noVerifiedMessage.technicalSupportTeam',
  },
  successMessageCallSettings: {
    defaultMessage: 'call settings',
    description: 'Success Message - call settings link',
    id: 'successMessage.callSettings',
  },
  successMessageCallSettingsSkippedDoorStation: {
    defaultMessage: 'If you skipped linking your device to the intercom, do this in' + ' the {link}.',
    description: 'Success Message - call settings skipped description',
    id: 'successMessage.callSettingsSkippedDoorStation',
  },
  successMessageCallSettingsSkippedHipmo: {
    defaultMessage: 'If you skipped linking your device to the intercom, do this in' + ' the {link}.',
    description: 'Success Message - call settings skipped description',
    id: 'successMessage.callSettingsSkippedHipmo',
  },
  successMessageCallSettingsSkippedThirdParty: {
    defaultMessage: 'If you skipped linking your device to the intercom, do this in' + ' the {link}.',
    description: 'Success Message - call settings skipped description',
    id: 'successMessage.callSettingsSkippedThirdParty',
  },
  successMessageCallSettingsSkippedX2N: {
    defaultMessage: 'If you skipped linking the devices to the intercom, you can do' + ' this in the {link}.',
    description: 'Success Message - call settings skipped description',
    id: 'successMessage.callSettingsSkippedX2N',
  },
  successMessageDescriptionDoorStation: {
    defaultMessage:
      'We’ve added the device to your My2N installation. To complete the' +
      ' settings, check out our step-by-step {linkSettingsGuide}.',
    description: 'Success Message - description',
    id: 'successMessage.describtion.doorStation',
  },
  successMessageDescriptionHipmo: {
    defaultMessage: 'Your smartphone or tablet is ready to open doors.',
    description: 'Success Message - description',
    id: 'successMessage.description.hipmo',
  },
  successMessageDescriptionThirdParty: {
    defaultMessage: 'Your new device is ready to make video calls or open doors.',
    description: 'Success Message - description',
    id: 'successMessage.description.thirdParty',
  },
  successMessageDescriptionX2N: {
    defaultMessage: 'Your device is up and running.',
    description: 'Success Message - description',
    id: 'successMessage.description.x2n',
  },
  successMessageMessage: {
    defaultMessage: 'Success! Device is added',
    description: 'Success Message - message',
    id: 'successMessage.message',
  },
  successMessageSettingsGuide: {
    defaultMessage: 'settings guide',
    description: 'Success Message - settings guide link',
    id: 'successMessage.settingsGuide',
  },
  waitingMessageDescription: {
    defaultMessage:
      'The device hasn’t been verified. Our system will keep on trying' +
      ' automatically. Once the device has been added and verified, you can proceed' +
      ' with the settings.',
    description: 'Waiting Message - description',
    id: 'waitingMessage.description',
  },
  waitingMessageMessage: {
    defaultMessage: 'We couldn’t verify your device',
    description: 'Waiting Message - message',
    id: 'waitingMessage.message',
  },
});
