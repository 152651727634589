import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { headSiteData } from '../../../api/siteRequest';
import LoadingButton from '../../../components/LoadingButton';
import { DEVICE_TYPE, SERVICE } from '../../../config/devices';
import { SITE_TYPE } from '../../../config/sites';
import apartmentActions, { selectors as apartmentsSelectors } from '../../../modules/apartments';
import { selectors as deviceSelectors } from '../../../modules/devices';
import actions from '../../../modules/devices';
import { handleSaveMultipleDevicesCallSettings } from '../../../modules/devices/helpers/saveDeviceHelper';
import { saveMultipleDevicesCallSettingsRequest } from '../../../modules/devices/saveActions';
import actionsSite, { selectors as sitesSelectors } from '../../../modules/sites';
import { selectors as userSelectors } from '../../../modules/user';
import { getApartmentValidationSchema } from '../../../validations/apartmentFormValidationSchema';
import { addThirdPartyDevice } from '../actions';
import AddDeviceModal from '../AddDeviceModal';
import CallSettingsStep from '../CallSettingsStep';
import messagesCallSettings from '../CallSettingsStep/messages';
import DeviceTitle from '../DeviceControl/DeviceTitle';
import FinishedStep from '../FinishedStep/FinishedStep';
import messagesFinished from '../FinishedStep/messages';
import { getValidationParameters, isAvailableForApartment } from '../helpers';
import StatusMessage from '../StatusMessage';
import messages from './messages';
import OneStep from './OneStep';
import messagesStepOne from './OneStep/messages';

// eslint-disable-next-line max-statements
const ThirdPartyForm = ({
  apartments,
  currentUser,
  deviceTypes,
  finishedData,
  floors,
  isAdvanceFinished,
  isFinished,
  onClearModal,
  onClearModalData,
  onClose,
  onLoadApartments,
  onLoadFloors,
  onLoadSiteUsers,
  onSelectDevice,
  onSubmit,
  open,
  siteType,
  users,
  validationExistName,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [callSettings, setCallSettings] = useState([]);
  const { companyId, siteId } = validationExistName;

  useEffect(() => {
    onLoadApartments(companyId, siteId);
    onLoadFloors(companyId, siteId);
    onLoadSiteUsers();
  }, []);

  useEffect(() => {
    if (!isFinished) {
      setStep(1);
    } else if (isFinished && step < 2) {
      onLoadSiteUsers();
      setStep(2);
    } else if (isFinished && isAdvanceFinished && step < 3) {
      setStep(3);
    }
  }, [isFinished, isAdvanceFinished, step]);

  const validationSchema = Yup.object().shape({
    apartmentId: Yup.mixed().when('isSiteMDU', {
      is: true,
      otherwise: (schema) => schema.notRequired(),
      then: (schema) =>
        schema.test(
          'isApartmentId',
          <FormattedMessage {...messages.thirdPartyFormApartmentRequired} />,
          (value) => Number.isInteger(value) || value === 'createNewApartment'
        ),
    }),
    existApartment: Yup.boolean().required(),
    existUser: Yup.boolean().required(),
    name: Yup.string()
      .required(<FormattedMessage {...messages.thirdPartyFormRequired} />)
      .max(50, <FormattedMessage {...messages.thirdPartyFormTooLong} />)
      .test({
        message: <FormattedMessage {...messages.thirdPartyFormNameExist} />,
        name: 'isExistName',
        test: async (name) => {
          try {
            let state = true;
            if (!name || name.length === 0) {
              return true;
            }
            await headSiteData({ companyId, siteId }, 'devices', { name }, false).then((response) => {
              state = response.status === 404;
            });

            return state;
          } catch (error) {
            return true;
          }
        },
      }),
    newApartment: Yup.object().when('apartmentId', {
      is: 'createNewApartment',
      then: (schema) =>
        schema.shape({
          ...getApartmentValidationSchema(siteType === SITE_TYPE.MDU, companyId, siteId, formatMessage),
        }),
    }),
    newUser: Yup.object().when('existUser', {
      is: false,
      then: (schema) =>
        schema.shape({
          email: Yup.string()
            .required(<FormattedMessage {...messages.thirdPartyFormRequired} />)
            .email(<FormattedMessage {...messages.thirdPartyFormEmailNoValid} />),
          firstName: Yup.string().required(<FormattedMessage {...messages.thirdPartyFormRequired} />),
          lastName: Yup.string().required(<FormattedMessage {...messages.thirdPartyFormRequired} />),
        }),
    }),
    user: Yup.number().when('existUser', {
      is: true,
      then: (schema) => schema.required(<FormattedMessage {...messages.thirdPartyFormRequired} />),
    }),
  });

  const availableForApartment = isAvailableForApartment(deviceTypes, DEVICE_TYPE.THIRD_PARTY);

  const onCreateNewDevice = (form) => {
    setStep(1);
    form.resetForm();
    onSelectDevice(null);
    onClearModal();
    onClearModalData();
  };

  const getActions = (form) => {
    if (step === 1) {
      return (
        <>
          <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            <Button onClick={() => onCreateNewDevice(form)} startIcon={<ArrowBackIcon />} sx={{ marginRight: 'auto' }}>
              <FormattedMessage {...messagesStepOne.formActionBackBtn} />
            </Button>
          </Box>
          <Button onClick={onClose}>
            <FormattedMessage {...messagesStepOne.formActionCancelBtn} />
          </Button>
          <LoadingButton
            color="primary"
            disabled={!form.isValid}
            endIcon={<ArrowForwardIcon />}
            isLoading={form.isSubmitting}
            type="submit"
            variant="contained"
          >
            <FormattedMessage {...messagesStepOne.formActionNextBtn} />
          </LoadingButton>
        </>
      );
    }

    if (step === 2) {
      return (
        <>
          <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            <Button disabled startIcon={<ArrowBackIcon />} sx={{ marginRight: 'auto' }}>
              <FormattedMessage {...messagesCallSettings.formActionBackButton} />
            </Button>
          </Box>
          <Button onClick={() => setStep(4)}>
            <FormattedMessage {...messagesCallSettings.formActionFinishLaterButton} />
          </Button>
          <Button
            color="primary"
            endIcon={<ArrowForwardIcon />}
            onClick={() => {
              if (callSettings.state.devicesCallSettings.some((device) => device.selected)) {
                handleSaveMultipleDevicesCallSettings(callSettings, (values) =>
                  dispatch(saveMultipleDevicesCallSettingsRequest(values))
                );
              } else {
                setStep(3);
              }
            }}
            type="button"
            variant="contained"
          >
            <FormattedMessage {...messagesCallSettings.formActionFinishedButton} />
          </Button>
        </>
      );
    }

    if (step === 3) {
      return (
        <>
          <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            <Button disabled startIcon={<ArrowBackIcon />} sx={{ marginRight: 'auto' }}>
              <FormattedMessage {...messagesFinished.formActionBackBtn} />
            </Button>
          </Box>
          <Button onClick={() => onCreateNewDevice(form)}>
            <FormattedMessage {...messagesFinished.formActionAddNewDeviceBtn} />
          </Button>
          <Button color="primary" endIcon={<DoneIcon />} onClick={onClose} type="button" variant="contained">
            <FormattedMessage {...messagesFinished.formActionCloseBtn} />
          </Button>
        </>
      );
    }

    return null;
  };

  const onSubmitForm = (values, formActions) => {
    onSubmit(values, formActions, floors);
  };

  return (
    <Formik
      initialValues={{
        apartmentId: null,
        existApartment: true,
        existUser: true,
        isSiteMDU: availableForApartment && siteType === SITE_TYPE.MDU,
        name: '',
        newApartment: {
          floor: '',
          hasForm: true,
          name: '',
          number: '',
        },
        newUser: {
          email: '',
          firstName: '',
          lastName: '',
        },
        user: currentUser?.id,
      }}
      onSubmit={onSubmitForm}
      render={(form) => (
        <Form autoComplete="off" noValidate>
          <AddDeviceModal
            actions={getActions(form)}
            title={<DeviceTitle device={DEVICE_TYPE.THIRD_PARTY} open={open} onClose={onClose} />}
            open={open}
            onClose={onClose}
          >
            <StatusMessage />
            {step === 1 && (
              <OneStep
                apartments={apartments}
                availableForApartment={availableForApartment}
                currentUser={currentUser}
                floors={floors}
                handleChange={form.handleChange}
                siteType={siteType}
                users={users}
                values={form.values}
              />
            )}
            {step === 2 && (
              <CallSettingsStep
                addedDevice={finishedData || {}}
                companyId={companyId}
                handleChange={setCallSettings}
                siteId={siteId}
              />
            )}
            {step >= 3 && (
              <FinishedStep
                callSettingsSkipped={step === 4}
                device={finishedData}
                deviceModalType={DEVICE_TYPE.THIRD_PARTY}
              />
            )}
          </AddDeviceModal>
        </Form>
      )}
      validationSchema={validationSchema}
    />
  );
};

ThirdPartyForm.propTypes = {
  apartments: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lastName: PropTypes.string,
  }),
  deviceTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  finishedData: PropTypes.shape({
    services: PropTypes.shape({
      [SERVICE.NOTIFICATION]: PropTypes.shape({
        qrCodeUrl: PropTypes.string,
      }),
    }),
    userId: PropTypes.number,
  }),
  floors: PropTypes.arrayOf(PropTypes.object).isRequired,
  isAdvanceFinished: PropTypes.bool,
  isFinished: PropTypes.bool,
  onClearModal: PropTypes.func.isRequired,
  onClearModalData: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onLoadApartments: PropTypes.func.isRequired,
  onLoadFloors: PropTypes.func.isRequired,
  onLoadSiteUsers: PropTypes.func.isRequired,
  onSelectDevice: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  siteType: PropTypes.oneOf(Object.keys(SITE_TYPE)).isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      lastName: PropTypes.string,
    })
  ).isRequired,
  validationExistName: PropTypes.shape({
    accessToken: PropTypes.string,
    companyId: PropTypes.number,
    siteId: PropTypes.number,
  }),
};

ThirdPartyForm.defaultProps = {
  currentUser: null,
  finishedData: null,
  isAdvanceFinished: false,
  isFinished: false,
  validationExistName: {
    accessToken: null,
    companyId: null,
    siteId: null,
  },
};

const mapStateToProps = (state) => ({
  apartments: apartmentsSelectors.getApartmentsListGrouped(state),
  currentUser: userSelectors.getCurrentUserSelector(state),
  deviceTypes: deviceSelectors.getCompanyDeviceTypes(state),
  finishedData: deviceSelectors.getFinishedDataDeviceModal(state),
  floors: apartmentsSelectors.getFloors(state),
  isAdvanceFinished: deviceSelectors.isAdvanceFinishedAddDeviceModal(state),
  isFinished: deviceSelectors.isFinishedAddDeviceModal(state),
  siteType: sitesSelectors.getCurrentSiteType(state),
  users: sitesSelectors.getSiteUsers(state).filter((user) => user.email),
  validationExistName: getValidationParameters(state),
});

const mapDispatchToProps = {
  onClearModalData: actions.addDeviceClearModalData,
  onLoadApartments: apartmentActions.getApartmentsList,
  onLoadFloors: apartmentActions.getSiteFloorsList,
  onLoadSiteUsers: actionsSite.getSitesUsersNames,
  onSubmit: addThirdPartyDevice,
};

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyForm);
