import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormikFields } from '../../../../components';
import messages from './messages';
import SecurityCodeField from './SecurityCodeField';

const SecurityCodeControl = ({ disabled, helperText }) => (
  <FormikFields.Input
    disabled={disabled}
    helperText={helperText}
    InputProps={{ inputComponent: SecurityCodeField }}
    label={<FormattedMessage {...messages.securityCodeControlInputLabel} />}
    name="securityCode"
    required={!disabled}
  />
);

SecurityCodeControl.propTypes = {
  disabled: PropTypes.bool.isRequired,
  helperText: PropTypes.string.isRequired,
};

export default SecurityCodeControl;
