import moment from 'moment';
import { MOBILE_VIDEO_STATUS } from '../config/consts';

export const isInRange = (licenceModel, range) =>
  moment(licenceModel?.poolExpiration || licenceModel?.validityTo).diff(moment(), 'days') < range;

export const getIsValidUntil = (status, licenceModel, subscribed = false) => {
  if (MOBILE_VIDEO_STATUS.ACTIVE === status && isInRange(licenceModel, 45)) {
    return true;
  }

  if (MOBILE_VIDEO_STATUS.STANDARD === status && !subscribed && isInRange(licenceModel, 45)) {
    return true;
  }

  if (MOBILE_VIDEO_STATUS.TRIAL_ACTIVE === status && isInRange(licenceModel, 7)) {
    return true;
  }
  return false;
};

export const TIME_UNIT = {
  DAYS: 'DAYS',
  HOURS: 'HOURS',
};

export const isActivable = (status) =>
  status === MOBILE_VIDEO_STATUS.ENABLED || status === MOBILE_VIDEO_STATUS.DISABLED;

export const isExpirable = (status) => status === MOBILE_VIDEO_STATUS.ACTIVE || status === MOBILE_VIDEO_STATUS.EXPIRED;

export const getTerminationTime = (date, timeUnit) => {
  let number;
  if (timeUnit === TIME_UNIT.HOURS) {
    number = 3600000;
  } else {
    number = 1000 * 3600 * 24;
  }
  return Math.round(Math.abs(new Date(date) - new Date(Date.now())) / number);
};
