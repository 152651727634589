import { Box, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { useGetApartmentUserForAaByIdQuery } from '../../../apiRtk/be4feApi';
import { getApartmentAdminUserDetailInfo, getApartmentUser } from '../../../apiRtk/dataTransform/AATranforms';
import { PATHS } from '../../../config/consts';
import { useUrlParams } from '../../../helpers/urlParams';
import { useApartmentComposedIdParam } from '../../../hooks/useComposedIdParamHook';
import { getApartmentsAccessGroups, getUserAccessGroups } from '../../../modules/apartmentAdmin/actions';
import { ApartmentUser } from '../../../modules/apartmentAdmin/data';
import {
  getApartmentAccessGroupsSelector,
  getUserAccessGroupsSelector,
} from '../../../modules/apartmentAdmin/selectors';
import ApartmentAdminUserMVService from './ApartmentAdminUserMVService';
import { DetailItem } from './components/DetailItem';
import { UserDetailAccessSettings } from './components/UserAccessSettings';
import { UserDetail } from './components/UserDetial/UserDetial';
import { UserTitleWithSkeleton } from './components/UserTitleWithSkeleton';
import messages from './messages';
import { isMobileVideoSectionVisible } from './utils/utils';

const gridItemSize = {
  lg: 4,
  sm: 6,
  xs: 12,
};

export function ApartmentAdminUserDetailPage(): React.JSX.Element {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const composedId = useApartmentComposedIdParam();
  const { userId } = useUrlParams();
  const dispatch = useDispatch();
  const { isFetching: isFetchingApartmentGroups } = useSelector(getApartmentAccessGroupsSelector);
  const { isFetching: isFetchingUserGroups } = useSelector(getUserAccessGroupsSelector);

  useEffect(() => {
    if (composedId && userId) {
      if (!isFetchingApartmentGroups) {
        dispatch(getApartmentsAccessGroups(composedId.companyId, composedId.siteId, composedId.apartmentId, userId));
      }
      if (!isFetchingUserGroups) {
        dispatch(getUserAccessGroups(composedId.companyId, composedId.siteId, composedId.apartmentId, userId));
      }
    }
  }, [composedId?.apartmentId, composedId?.companyId, composedId?.siteId, userId]);

  const userDetailQuery = useGetApartmentUserForAaByIdQuery(
    {
      apartmentId: composedId?.apartmentId ?? 0,
      companyId: composedId?.companyId ?? 0,
      siteId: composedId?.siteId ?? 0,
      userId: userId ?? 0,
    },
    { refetchOnMountOrArgChange: true }
  );

  if (composedId === undefined) {
    return <Navigate to={PATHS.USERS_IN_APARTMENTS} />;
  }

  const apartmentUser: ApartmentUser | undefined = userDetailQuery.data && getApartmentUser(userDetailQuery.data.user);

  return (
    <>
      <Box sx={{ pb: 0.5 }}>
        <UserTitleWithSkeleton
          isFetching={userDetailQuery.isFetching}
          onBackButtonClick={() => navigate(PATHS.USERS_IN_APARTMENTS)}
          userDetailInfo={getApartmentAdminUserDetailInfo(userDetailQuery.data)}
        />
      </Box>

      <Grid container spacing={2}>
        <Grid item {...gridItemSize}>
          <DetailItem title={formatMessage(messages.AAUserDetailTitle)} isFetching={userDetailQuery.isFetching}>
            <>{apartmentUser && <UserDetail user={apartmentUser} apartmentIdsInfo={composedId} />}</>
          </DetailItem>
        </Grid>

        {isMobileVideoSectionVisible(apartmentUser) && (
          <Grid item {...gridItemSize}>
            <ApartmentAdminUserMVService
              apartmentId={composedId?.apartmentId}
              companyId={composedId?.companyId}
              siteId={composedId?.siteId}
              userId={userId!}
              paidDevicesCount={userDetailQuery.data?.apartment.paidDevicesCount}
              paidDevicesLimit={userDetailQuery.data?.apartment.paidDevicesLimit}
              isUnlicensed={userDetailQuery.data?.apartment.apartmentMobileVideoState === 'UNLICENSED'}
            />
          </Grid>
        )}

        <Grid item {...gridItemSize}>
          <DetailItem
            title={formatMessage(messages.AAUserDetailAccessTitle)}
            isFetching={userDetailQuery.isFetching || isFetchingApartmentGroups || isFetchingUserGroups}
          >
            <>
              {userDetailQuery.data?.user && (
                <UserDetailAccessSettings
                  user={getApartmentUser(userDetailQuery.data.user)}
                  apartmentIdsInfo={composedId}
                />
              )}
            </>
          </DetailItem>
        </Grid>
      </Grid>
    </>
  );
}
