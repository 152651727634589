import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PropTypes from 'prop-types';
import React from 'react';

const Icon = ({ count, isError }) => {
  if (count === 0) {
    return <CancelIcon sx={{ color: 'text.disabled' }} />;
  }
  if (isError) {
    return <ErrorIcon color="error" />;
  }
  return <CheckCircleIcon color="success" />;
};

Icon.propTypes = {
  count: PropTypes.number.isRequired,
  isError: PropTypes.bool.isRequired,
};

export default Icon;
