import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useTimeoutEffect } from '../../hooks/useTimeoutEffect';
import { selectors } from '../../modules/user';
import { My2NAppAccessIcon } from '../_Icons';
import messages from './messages';
import Panel from './Panel';

function getAvatarColor(isError: boolean, isEnabled: boolean, hasAuthKey: boolean) {
  if (isError) {
    return 'text.disabled';
  }
  if (isEnabled && hasAuthKey) {
    return 'success.main';
  }
  return 'primary.main';
}

function PanelMy2NAppAccess({ isActivated }: { isActivated: boolean }) {
  const isCurrentlyActivated = useTimeoutEffect(isActivated);
  const { formatMessage } = useIntl();
  const { hasAuthKey, isEnabled, isError } = {
    hasAuthKey: useSelector(selectors.hasUserMy2NAppAccessAuthKey),
    isEnabled: useSelector(selectors.getUserMy2NAppAccessEnabledStatus),
    isError: useSelector(selectors.isUserMy2NAppAccessStatusError),
  };

  return (
    <Panel
      errorMessage={formatMessage(messages.panelMy2NAppAccessErrorMessage)}
      isActivated={isCurrentlyActivated}
      isError={isError}
    >
      <Stack alignItems="center" direction="row" spacing={2}>
        <Avatar sx={{ bgcolor: getAvatarColor(isError, isEnabled, hasAuthKey) }}>
          <My2NAppAccessIcon />
        </Avatar>
        {isError ? (
          <Typography color="text.disabled" variant="body1">
            <FormattedMessage {...messages.panelMy2NAppAccessErrorLabel} />
          </Typography>
        ) : (
          <Typography color={hasAuthKey ? 'inherit' : 'primary.main'} variant="body1">
            {isEnabled && hasAuthKey ? (
              <FormattedMessage
                {...messages.panelMy2NAppAccessActivatedLabel}
                values={{
                  box: (chunks) => (
                    <Box component="span" sx={{ color: 'success.main' }}>
                      {chunks}
                    </Box>
                  ),
                }}
              />
            ) : (
              <FormattedMessage {...messages.panelMy2NAppAccessLabel} />
            )}
          </Typography>
        )}
      </Stack>
    </Panel>
  );
}

export default PanelMy2NAppAccess;
