import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormikFields, UserSelectField } from '../../../../components';
import { SITE_TYPE } from '../../../../config/sites';
import ApartmentFormField from '../../ApartmentFormField';
import NewApartmentForm from '../../NewApartmentForm';
import messages from './messages';

const OneStep = ({ apartments, availableForApartment, currentUser, floors, handleChange, siteType, users, values }) => (
  <>
    <Typography sx={{ mb: 2 }} variant="body2">
      <FormattedMessage {...messages.oneStepDescription} />
    </Typography>
    <UserSelectField currentUser={currentUser} existUser={values.existUser} onChange={handleChange} users={users} />
    <Grid container spacing={2}>
      <Grid item xs>
        <ApartmentFormField
          apartments={apartments}
          disabled={!availableForApartment}
          name="apartmentId"
          onChange={handleChange}
          required={siteType === SITE_TYPE.MDU}
          value={values?.apartmentId}
        />
      </Grid>
      <Grid item xs>
        <FormikFields.Input
          autoFocus
          helperText={<FormattedMessage {...messages.oneStepNameHelperText} />}
          label={<FormattedMessage {...messages.oneStepNameLabel} />}
          name="name"
          required
        />
      </Grid>
    </Grid>
    {values?.apartmentId === 'createNewApartment' && (
      <NewApartmentForm floors={floors} onChange={handleChange} siteType={siteType} values={values} />
    )}
  </>
);

OneStep.propTypes = {
  apartments: PropTypes.arrayOf(PropTypes.object).isRequired,
  availableForApartment: PropTypes.bool.isRequired,
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lastName: PropTypes.string,
  }),
  floors: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func.isRequired,
  siteType: PropTypes.oneOf(Object.keys(SITE_TYPE)).isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  values: PropTypes.shape({
    apartmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    existUser: PropTypes.bool,
  }).isRequired,
};

OneStep.defaultProps = {
  currentUser: null,
};

export default OneStep;
