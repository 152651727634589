import { defineMessages } from 'react-intl';

export default defineMessages({
  AAUserDetailCancel: {
    defaultMessage: 'Cancel',
    id: 'AA.userDetail.cancel',
  },
  AAUserDetailEmailHelper: {
    defaultMessage: 'Email can’t be edited, you must create new user.',
    id: 'AA.userDetail.emailHelper',
  },
  AAUserDetailEmailLabel: {
    defaultMessage: 'User email',
    id: 'AA.userDetail.emailLabel',
  },
  AAUserDetailFirstNameLabel: {
    defaultMessage: 'First name',
    id: 'AA.userDetail.firstNameLabel',
  },
  AAUserDetailMsgErr: {
    defaultMessage: 'There was an error, please try again',
    id: 'AA.userDetail.msgErr',
  },
  AAUserDetailMsgSucc: {
    defaultMessage: 'User info edited successfully',
    id: 'AA.userDetail.msgSucc',
  },
  AAUserDetailSubmit: {
    defaultMessage: 'Save changes',
    id: 'AA.userDetail.submit',
  },
  AAUserDetailSurnameLabel: {
    defaultMessage: 'Surname',
    id: 'AA.userDetail.surnameLabel',
  },
});
