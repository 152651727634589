import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import ApartmentFreeTooltip from './ApartmentFreeTooltip';
import Icon from './Icon';

const Apartment = ({ children, count, isError, isLastChild, tooltip }) => (
  <Box
    alignItems="center"
    display="flex"
    mb={
      !isLastChild && {
        sm: 1.5,
        xs: 0.5,
      }
    }
  >
    <Box alignItems="center" display="flex" sx={{ mr: 1 }}>
      <Icon count={count} isError={isError} />
    </Box>
    {tooltip ? (
      <ApartmentFreeTooltip count={count}>{children}</ApartmentFreeTooltip>
    ) : (
      <Typography color={count > 0 ? 'text.primary' : 'text.disabled'} component="div" noWrap variant="body2">
        {children}
      </Typography>
    )}
  </Box>
);

Apartment.propTypes = {
  children: PropTypes.element.isRequired,
  count: PropTypes.number,
  isError: PropTypes.bool,
  isLastChild: PropTypes.bool,
  tooltip: PropTypes.bool,
};

Apartment.defaultProps = {
  count: 0,
  isError: false,
  isLastChild: false,
  tooltip: false,
};

export default Apartment;
