import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { headSiteData } from '../../../api/siteRequest';
import { LoadingButton } from '../../../components';
import { CONFIGURATION_MODE, CONNECTION_MODE, DEVICE_SERIAL_CODE_MASK } from '../../../config/devices';
import { SITE_TYPE } from '../../../config/sites';
import { isAnsweringUnit } from '../../../helpers/devices';
import useBreakpoints from '../../../helpers/useBreakpoints';
import apartmentActions, { selectors as apartmentsSelectors } from '../../../modules/apartments';
import { selectors as companySelectors } from '../../../modules/company';
import actions, { selectors as deviceSelectors } from '../../../modules/devices';
import { handleSaveMultipleDevicesCallSettings } from '../../../modules/devices/helpers/saveDeviceHelper';
import { saveMultipleDevicesCallSettingsRequest } from '../../../modules/devices/saveActions';
import { selectors as siteSelectors } from '../../../modules/sites';
import { getApartmentValidationSchema } from '../../../validations/apartmentFormValidationSchema';
import { add2NDevice } from '../actions';
import AddDeviceModal from '../AddDeviceModal';
import CallSettingsStep from '../CallSettingsStep';
import messagesCallSettings from '../CallSettingsStep/messages';
import DeviceTitle from '../DeviceControl/DeviceTitle';
import FinishedStep from '../FinishedStep';
import messagesFinished from '../FinishedStep/messages';
import StatusMessage from '../StatusMessage';
import Loading from './Loading';
import messages from './messages';
import OneStep from './OneStep';
import messagesStepOne from './OneStep/messages';
import TwoStep from './TwoStep';
import messagesStepTwo from './TwoStep/messages';

// eslint-disable-next-line max-statements
const X2NDeviceForm = ({
  apartments,
  companyId,
  deviceTypes,
  finishedData,
  floors,
  isAdvanceFinished,
  isFinished,
  isWait,
  my2nId,
  onClearModal,
  onClearModalData,
  onClose,
  onLoadApartments,
  onLoadFloors,
  onSelectDevice,
  onSubmit,
  open,
  site,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [callSettings, setCallSettings] = useState([]);
  const { upSm } = useBreakpoints();

  useEffect(() => {
    onLoadApartments(companyId, site.id);
    onLoadFloors(companyId, site.id);
  }, []);

  useEffect(() => {
    if (isWait && step !== 3) {
      setStep(3);
    } else if (isFinished && step < 4) {
      if (isAnsweringUnit(finishedData?.type)) {
        setStep(4);
      } else {
        setStep(5);
      }
    } else if (isFinished && isAdvanceFinished && step < 5) {
      setStep(5);
    }
  }, [isWait, isFinished, isAdvanceFinished, step]);

  const validationSchema = Yup.object().shape({
    apartmentId: Yup.mixed().when(['isAvailableForApartment', 'isSiteMDU', 'isDeviceIntercomOrAccessUnit'], {
      is: (isAvailableForApartment, isSiteMDU, isDeviceIntercomOrAccessUnit) =>
        isAvailableForApartment && isSiteMDU && !isDeviceIntercomOrAccessUnit,
      otherwise: (schema) => schema.notRequired(),
      then: (schema) =>
        schema.test(
          'isApartmentId',
          <FormattedMessage {...messages.x2NDeviceFormRequired} />,
          (value) => Number.isInteger(value) || value === 'createNewApartment'
        ),
    }),
    configurationMode: Yup.string().required(<FormattedMessage {...messages.x2NDeviceFormRequired} />),
    connectionMode: Yup.string().required(<FormattedMessage {...messages.x2NDeviceFormRequired} />),
    deviceType: Yup.string().required(<FormattedMessage {...messages.x2NDeviceFormRequired} />),
    existApartment: Yup.boolean().required(),
    identifier: Yup.string().when('isIDT', {
      is: true,
      then: (schema) =>
        schema
          .max(30, <FormattedMessage {...messages.x2NDeviceFormTooLong} />)
          .test(
            'isExistIdentifier',
            <FormattedMessage {...messages.x2NDeviceFormRequired} />,
            (value) => (step === 1 && !value) || (value && value.length > 0)
          )
          .test('isValidIdentifier', <FormattedMessage {...messages.x2NDeviceFormIdentifierNoValid} />, (value) =>
            new RegExp(/^[\w-]+$/i).test(value)
          ),
    }),
    name: Yup.string()
      .required(<FormattedMessage {...messages.x2NDeviceFormRequired} />)
      .max(50, <FormattedMessage {...messages.x2NDeviceFormTooLong} />)
      .test({
        message: <FormattedMessage {...messages.x2NDeviceFormNameExist} />,
        name: 'isExistName',
        test: async (name) => {
          try {
            let state = true;
            if (!name || name.length === 0) {
              return true;
            }
            await headSiteData({ companyId, siteId: site.id }, 'devices', { name }, false).then((response) => {
              state = response.status === 404;
            });

            return state;
          } catch (error) {
            return true;
          }
        },
      }),
    newApartment: Yup.object().when('apartmentId', {
      is: 'createNewApartment',
      then: (schema) =>
        schema.shape({
          ...getApartmentValidationSchema(site.type === SITE_TYPE.MDU, companyId, site.id, formatMessage),
        }),
    }),
    securityCode: Yup.string().when('isIDT', {
      is: false,
      then: (schema) =>
        schema
          .required(<FormattedMessage {...messages.x2NDeviceFormRequired} />)
          .test('isValidSecurityCode', <FormattedMessage {...messages.x2NDeviceFormSecurityCodeNoValid} />, (value) =>
            new RegExp(/^([A-Z0-9]{4})-?([A-Z0-9]{4})-?([A-Z0-9]{4})-?([A-Z0-9]{4})$/i).test(value)
          ),
    }),
    serialNumber: Yup.string()
      .required(<FormattedMessage {...messages.x2NDeviceFormRequired} />)
      .test('isValidSerialNumber', <FormattedMessage {...messages.x2NDeviceFormSerialNumberNoValid} />, (value) =>
        new RegExp(DEVICE_SERIAL_CODE_MASK).test(value)
      ),
  });

  const handleSubmit = (values, formActions) => {
    if (step === 1) {
      setStep(2);
      formActions.setSubmitting(false);
    } else {
      onSubmit(values, formActions, floors, setStep);
    }
  };

  const onCreateNewDevice = (form) => {
    setStep(1);
    form.resetForm();
    onSelectDevice(null);
    onClearModal();
    onClearModalData();
  };

  const getActions = (form) => {
    if (step === 1) {
      return (
        <>
          <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            <Button onClick={() => onCreateNewDevice(form)} startIcon={<ArrowBackIcon />} sx={{ marginRight: 'auto' }}>
              <FormattedMessage {...messagesStepOne.formActionBackBtn} />
            </Button>
          </Box>
          <Button onClick={onClose}>
            <FormattedMessage {...messagesStepOne.formActionCancelBtn} />
          </Button>
          <Button
            color="primary"
            disabled={!form.isValid}
            endIcon={<ArrowForwardIcon />}
            onClick={form.handleSubmit}
            type="submit"
            variant="contained"
          >
            <FormattedMessage {...messagesStepOne.formActionNextBtn} />
          </Button>
        </>
      );
    }

    if (step === 2) {
      return (
        <>
          <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            <Button onClick={() => setStep(step - 1)} startIcon={<ArrowBackIcon />} sx={{ marginRight: 'auto' }}>
              <FormattedMessage {...messagesStepTwo.formActionBackBtn} />
            </Button>
          </Box>
          <Button onClick={onClose}>
            <FormattedMessage {...messagesStepTwo.formActionCancelBtn} />
          </Button>
          <LoadingButton
            color="primary"
            disabled={!form.isValid}
            endIcon={<ArrowForwardIcon />}
            isLoading={form.isSubmitting}
            onClick={form.handleSubmit}
            type="submit"
            variant="contained"
          >
            <FormattedMessage {...messagesStepTwo.formActionNextBtn} />
          </LoadingButton>
        </>
      );
    }

    if (step === 4) {
      return (
        <>
          <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            <Button disabled startIcon={<ArrowBackIcon />} sx={{ marginRight: 'auto' }}>
              <FormattedMessage {...messagesCallSettings.formActionBackButton} />
            </Button>
          </Box>
          <Button onClick={() => setStep(6)}>
            <FormattedMessage {...messagesCallSettings.formActionFinishLaterButton} />
          </Button>
          <Button
            color="primary"
            endIcon={<ArrowForwardIcon />}
            onClick={() => {
              if (callSettings.state.devicesCallSettings.some((device) => device.selected)) {
                handleSaveMultipleDevicesCallSettings(callSettings, (values) =>
                  dispatch(saveMultipleDevicesCallSettingsRequest(values))
                );
              } else {
                setStep(5);
              }
            }}
            type="button"
            variant="contained"
          >
            <FormattedMessage {...messagesCallSettings.formActionFinishedButton} />
          </Button>
        </>
      );
    }

    if (step >= 5) {
      return (
        <>
          <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            <Button disabled startIcon={<ArrowBackIcon />} sx={{ marginRight: 'auto' }}>
              <FormattedMessage {...messagesFinished.formActionBackBtn} />
            </Button>
          </Box>
          <Button color="primary" onClick={() => onCreateNewDevice(form)}>
            <FormattedMessage {...messagesFinished.formActionAddNewDeviceBtn} />
          </Button>
          <Button color="primary" endIcon={<DoneIcon />} onClick={onClose} type="submit" variant="contained">
            <FormattedMessage {...messagesFinished.formActionCloseBtn} />
          </Button>
        </>
      );
    }

    return null;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        accessLog: false,
        apartmentId: null,
        configurationMode: CONFIGURATION_MODE.AUTOMATIC,
        connectionMode: CONNECTION_MODE.LOCAL,
        deviceType: '',
        existApartment: true,
        identifier: '',
        isAvailableForApartment: false,
        isDeviceIntercomOrAccessUnit: false,
        isIDT: false,
        isSiteMDU: false,
        name: '',
        newApartment: {
          floor: '',
          hasForm: true,
          name: '',
          number: '',
        },
        securityCode: '',
        serialNumber: '',
        storeHash: false,
      }}
      onSubmit={handleSubmit}
      render={(form) => (
        <Form autoComplete="off" noValidate>
          <AddDeviceModal actions={getActions(form)} title={<DeviceTitle device="X2N" />} open={open} onClose={onClose}>
            <StatusMessage />
            {step === 1 && (
              <OneStep
                apartments={apartments}
                companyId={companyId}
                deviceTypes={deviceTypes}
                floors={floors}
                formProps={form}
                isMobileView={!upSm}
                isShowApartmentField
                my2nId={my2nId}
                site={site}
              />
            )}
            {step === 2 && (
              <TwoStep
                deviceTypes={deviceTypes}
                formProps={form}
                onSetNextStep={() => {
                  setStep(3);
                  form.handleSubmit();
                }}
              />
            )}
            {step === 3 && <Loading onClose={onClose} />}
            {step === 4 && (
              <CallSettingsStep
                addedDevice={finishedData || {}}
                companyId={companyId}
                handleChange={setCallSettings}
                siteId={site.id}
              />
            )}
            {step >= 5 && (
              <FinishedStep callSettingsSkipped={step === 6} device={finishedData} deviceModalType={'X2N'} />
            )}
          </AddDeviceModal>
        </Form>
      )}
      validationSchema={validationSchema}
    />
  );
};

X2NDeviceForm.propTypes = {
  apartments: PropTypes.arrayOf(PropTypes.object).isRequired,
  companyId: PropTypes.number.isRequired,
  deviceTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  finishedData: PropTypes.shape({
    services: PropTypes.shape({
      NOTIFICATION: PropTypes.shape({
        qrCodeUrl: PropTypes.string,
      }),
    }),
    type: PropTypes.string,
    userId: PropTypes.number,
  }),
  floors: PropTypes.arrayOf(PropTypes.object).isRequired,
  isAdvanceFinished: PropTypes.bool,
  isFinished: PropTypes.bool.isRequired,
  isWait: PropTypes.bool.isRequired,
  my2nId: PropTypes.string.isRequired,
  onClearModal: PropTypes.func.isRequired,
  onClearModalData: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onLoadApartments: PropTypes.func.isRequired,
  onLoadFloors: PropTypes.func.isRequired,
  onSelectDevice: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  site: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
  }).isRequired,
};

X2NDeviceForm.defaultProps = {
  finishedData: null,
  isAdvanceFinished: false,
};

const mapStateToProps = (state) => ({
  apartments: apartmentsSelectors.getApartmentsListGrouped(state),
  companyId: companySelectors.getCompanyId(state),
  deviceTypes: deviceSelectors.getCompanyDeviceTypes(state),
  finishedData: deviceSelectors.getFinishedDataDeviceModal(state),
  floors: apartmentsSelectors.getFloors(state),
  isAdvanceFinished: deviceSelectors.isAdvanceFinishedAddDeviceModal(state),
  isFinished: deviceSelectors.isFinishedAddDeviceModal(state),
  isWait: deviceSelectors.isWaitAddDeviceModal(state),
  my2nId: siteSelectors.getMy2nId(state),
  site: siteSelectors.getSiteData(state),
});

const mapDispatchToProps = {
  onClearModalData: actions.addDeviceClearModalData,
  onLoadApartments: apartmentActions.getApartmentsList,
  onLoadFloors: apartmentActions.getSiteFloorsList,
  onSubmit: add2NDevice,
};

export default connect(mapStateToProps, mapDispatchToProps)(X2NDeviceForm);
