import React, { createRef, forwardRef } from 'react';
import MaskedInput from 'react-text-mask';

const MacAddressField = forwardRef(({ ...props }, ref) => {
  const inputRef = createRef(ref);
  return (
    <MaskedInput
      {...props}
      mask={[
        /[A-F0-9]/i,
        /[A-F0-9]/i,
        '-',
        /[A-F0-9]/i,
        /[A-F0-9]/i,
        '-',
        /[A-F0-9]/i,
        /[A-F0-9]/i,
        '-',
        /[A-F0-9]/i,
        /[A-F0-9]/i,
        '-',
        /[A-F0-9]/i,
        /[A-F0-9]/i,
        '-',
        /[A-F0-9]/i,
        /[A-F0-9]/i,
      ]}
      ref={inputRef}
    />
  );
});

export default MacAddressField;
