import { FormControl, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import Recaptcha from 'react-recaptcha';
import { PasswordInput } from '../../components/HookFormFields/PasswordInput';
import { Select } from '../../components/HookFormFields/Select';
import { OutlinedTextField } from '../../components/HookFormFields/TextField';
import messages from './messages';
import { RegisterGtc } from './RegisterGtc';

const styles = {
  formButtons: {
    p: 5,
    textAlign: 'center',
  },
  recaptcha: {
    mt: 3,
  },
};

export function RegisterForm() {
  const { formatMessage } = useIntl();

  const feedbackOptions: { label: string; value: string }[] = [
    { label: formatMessage(registerRefererOptionsMap['PRODUCT_SELLER']), value: 'PRODUCT_SELLER' },
    { label: formatMessage(registerRefererOptionsMap['PAPER_CARD']), value: 'PAPER_CARD' },
    { label: formatMessage(registerRefererOptionsMap['CONVENTION']), value: 'CONVENTION' },
    { label: formatMessage(registerRefererOptionsMap['ADVERTISEMENT']), value: 'ADVERTISEMENT' },
    { label: formatMessage(registerRefererOptionsMap['SOCIAL_NETWORKS']), value: 'SOCIAL_NETWORKS' },
    { label: formatMessage(registerRefererOptionsMap['NEWSLETTER']), value: 'NEWSLETTER' },
    { label: formatMessage(registerRefererOptionsMap['ARTICLE']), value: 'ARTICLE' },
    { label: formatMessage(registerRefererOptionsMap['OTHER']), value: 'OTHER' },
  ];

  const { formState, setValue } = useFormContext<IRegisterFormData>();

  return (
    <FormControl>
      <Grid container spacing={1}>
        <Grid item xs>
          <OutlinedTextField
            id="firstname-input"
            label={<FormattedMessage {...messages.registerFirstNameLabel} />}
            name="firstName"
            required
            fullWidth
            hasTopAndBottomMargin
          />
        </Grid>
        <Grid item xs>
          <OutlinedTextField
            id="lastname-input"
            label={<FormattedMessage {...messages.registerLastNameLabel} />}
            name="lastName"
            required
            fullWidth
            hasTopAndBottomMargin
          />
        </Grid>
      </Grid>
      <OutlinedTextField
        id="email-input"
        label={<FormattedMessage {...messages.registerEmailLabel} />}
        name="email"
        required
        type="email"
        hasTopAndBottomMargin
      />
      <PasswordInput
        id="password-input"
        label={formatMessage(messages.registerPasswordLabel)}
        name="password"
        required
        fullWidth
      />
      <OutlinedTextField
        id="companyname-input"
        label={<FormattedMessage {...messages.registerCompanyNameLabel} />}
        name="companyName"
        required
        hasTopAndBottomMargin
      />
      <Select label={formatMessage(messages.registerReferrerLabel)} name="referrer">
        {feedbackOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <RegisterGtc />
      <Box sx={styles.recaptcha}>
        <Recaptcha
          expiredCallback={() => setValue('captcha', '')}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onloadCallback={() => {}}
          render="explicit"
          sitekey={process.env.RECAPTCHA_SITE_KEY}
          verifyCallback={(response) => {
            setValue('captcha', response, { shouldValidate: true });
          }}
        />
      </Box>
      {formState.errors.captcha && formState.dirtyFields.captcha && <p>{formState.errors.captcha}</p>}
      <Box sx={styles.formButtons}>
        <Button
          color="primary"
          disabled={!formState.isValid}
          id="creteaccount-button"
          size="large"
          type="submit"
          variant="contained"
        >
          <FormattedMessage {...messages.registerTitle} />
        </Button>
      </Box>
    </FormControl>
  );
}

export interface IRegisterFormData {
  agree: boolean;
  captcha: string;
  companyName: string;
  email: string;
  firstName: string;
  lastName: string;
  newsletters: boolean;
  password: string;
  referrer?: string;
}

const registerRefererOptionsMap: { [key: string]: MessageDescriptor } = {
  ADVERTISEMENT: messages.registerReferrerOptionsADVERTISEMENT,
  ARTICLE: messages.registerReferrerOptionsARTICLE,
  CONVENTION: messages.registerReferrerOptionsCONVENTION,
  NEWSLETTER: messages.registerReferrerOptionsNEWSLETTER,
  OTHER: messages.registerReferrerOptionsOTHER,
  PAPER_CARD: messages.registerReferrerOptionsPAPER_CARD,
  PRODUCT_SELLER: messages.registerReferrerOptionsPRODUCT_SELLER,
  SOCIAL_NETWORKS: messages.registerReferrerOptionsSOCIAL_NETWORKS,
};
