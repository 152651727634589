import { yupResolver } from '@hookform/resolvers/yup';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, Grid, Stack, TextField } from '@mui/material';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useUpdateUserBasicInfoInApartmentMutation } from '../../../../../apiRtk/be4feApi';
import { OutlinedTextField as TextFieldForm } from '../../../../../components/HookFormFields/TextField';
import { ApartmentUser, ApartmentUserRole } from '../../../../../modules/apartmentAdmin/data';
import { IApartmentIdsInfo } from '../../../../../modules/apartments/store';
import { TYPE_DESCRIPTOR } from '../../../../../modules/notifications';
import { addSnackbarError, addSnackbarSuccess } from '../../../../../modules/notifications/actions';
import { isUserGuest } from '../../../../../modules/user/utils';
import { UserFormFieldNames } from '../../../../../validations/user/userCommonValidations';
import messages from './messages';
import { getValidationSchema, IFormData } from './validationScheme';

export interface IUserDetailData {
  user: ApartmentUser;
  apartmentIdsInfo: IApartmentIdsInfo;
}

export function UserDetail(props: IUserDetailData): React.JSX.Element {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [editInfo, { isLoading }] = useUpdateUserBasicInfoInApartmentMutation();

  const formMethods = useForm<IFormData>({
    defaultValues: {
      firstName: props.user.firstName || '',
      lastName: props.user.lastName,
    },
    mode: 'onChange',
    resolver: yupResolver(getValidationSchema(formatMessage, isFirstNameRequired(props.user))),
  });

  async function handleSubmit(data: IFormData): Promise<void> {
    try {
      const response = await editInfo({
        apartmentId: props.apartmentIdsInfo.apartmentId,
        companyId: props.apartmentIdsInfo.companyId,
        siteId: props.apartmentIdsInfo.siteId,
        userId: props.user.id,
        userModifyNameRequest: {
          firstName: data.firstName || undefined,
          lastName: data.lastName || '',
        },
      }).unwrap();

      dispatch(addSnackbarSuccess({ ...messages.AAUserDetailMsgSucc }));
      formMethods.reset({ firstName: response.firstName || '', lastName: response.lastName });
    } catch (e) {
      dispatch(addSnackbarError({ ...messages.AAUserDetailMsgErr }, TYPE_DESCRIPTOR));
    }
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <Stack spacing={1}>
          <TextField
            label={formatMessage(messages.AAUserDetailEmailLabel)}
            required
            disabled
            fullWidth
            defaultValue={props.user.email}
            helperText={formatMessage(messages.AAUserDetailEmailHelper)}
          />
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextFieldForm
                  label={formatMessage(messages.AAUserDetailFirstNameLabel)}
                  required={isFirstNameRequired(props.user)}
                  name={UserFormFieldNames.firstName}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldForm
                  required
                  label={formatMessage(messages.AAUserDetailSurnameLabel)}
                  name={UserFormFieldNames.lastName}
                  margin="normal"
                />
              </Grid>
            </Grid>
          </Box>
        </Stack>
        <Stack justifyContent={'flex-end'} spacing={1} direction={'row'}>
          <Button onClick={() => formMethods.reset()} disabled={!formMethods.formState.isDirty}>
            <FormattedMessage {...messages.AAUserDetailCancel} />
          </Button>
          <Button
            color="primary"
            startIcon={<DoneIcon />}
            type="submit"
            variant="contained"
            disabled={isLoading || !formMethods.formState.isValid || !formMethods.formState.isDirty}
          >
            <FormattedMessage {...messages.AAUserDetailSubmit} />
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
}

function isFirstNameRequired(user: ApartmentUser): boolean {
  return user.role !== ApartmentUserRole.APARTMENT_LOGIN_LESS && !isUserGuest(user.role);
}
